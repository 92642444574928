import axios from 'axios';
// constantes
const dataUsers = {
  array: [],
  org: [],
  roles: [],
  logo: [],
  loadingUser: false,
  errorUser: '',
  users:null
};
const BaseUrl = process.env.REACT_APP_URL_API;
const LOADING = 'LOADING_USER';
const ERROR = 'ERROR_USER';
const GET_OBTENER_USUARIOS = 'GET_OBETENER_USUARIOS';
const POST_CREAR_USUARIOS = 'POST_CREAR_USUARIO';
const DELETE_ELIMINAR_USUARIOS = 'DELETE_ELIMINAR_USUARIOS';
const PUT_EDITAR_USUARIOS = 'PUT_EDITAR_USUARIOS';

// reducer
export default function usuariosReducer(state = dataUsers, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loadingUser: true, errorUser:'', users:null };
    case ERROR: // TODO
      return { ...state, loadingUser: false,  errorUser: action.payload.error, users:null};
    case GET_OBTENER_USUARIOS:
      return {
        ...state,
        array: action.payload.payload[0],
        org: action.payload.payload[1],
        logo: action.payload.payload[2],
        roles: action.payload.payload[3],
        loadingUser: false,
        errorUser: '',
        users:null
      };
    case POST_CREAR_USUARIOS:
      return {
        ...state,
        loadingUser: false,
        errorUser: '',
        users:true
      };
    case DELETE_ELIMINAR_USUARIOS:
      return {
        ...state,
        loadingUser: false,
        errorUser: '',
        users:true
      };
    case PUT_EDITAR_USUARIOS:
      return {
        ...state,
        loadingUser: false,
        errorUser: '',
        users:true
      };
    default:
      return state;
  }
}

// // acciones
export const getUser = (token) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
    const config = {
      method: 'get',
      url: `${BaseUrl}/api/user`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    };
    const res = await axios(config);

    dispatch({
      type: GET_OBTENER_USUARIOS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

export const postUser = (token,userItems,usuario) => async (dispatch) => {
  const { code, name, organization, notification, email, phone, rol, password, logo, status } = userItems;
  try {
    dispatch({
      type: LOADING,
    });
    const data = {
      id:code,
      name,
      logo,
      organization,
      notification,
      email,
      phone,
      rol,
      status,
      password,
      createdBy:usuario.code,
      updatedBy:usuario.code
    };

    const config = {
      method: 'post',
      url: `${BaseUrl}/api/user`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data:{data},
    };
    const res = await axios(config);

    dispatch({
      type: POST_CREAR_USUARIOS,
      payload: res.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

export const putUser = (token,users,usuario) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
    const { id, name, organization, notification, email, phone, rol, password, logo, status,createdBy } = users;
    const data = {
      id,
      name,
      organization,
      notification,
      email,
      phone,
      rol,
      password,
      logo,
      status,
      createdBy,
      updatedBy:usuario.code
    };
    const config = {
      method: 'put',
      url: `${BaseUrl}/api/user`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data:{id,data},
    };
    const res = await axios(config);
    dispatch({
      type: PUT_EDITAR_USUARIOS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

export const deleteUser = (token,id) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const config = {
      method: 'delete',
      url: `${BaseUrl}/api/user`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data: { id },
    };

    const resDelete = await axios(config);
    dispatch({
      type: DELETE_ELIMINAR_USUARIOS,
      payload: resDelete.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};


