import axios from 'axios';
// constantes
const dataProfils = {
  message: '',
  loading: false,
  error: '',
};
const BaseUrl = process.env.REACT_APP_URL_API;
const LOADING = 'LOADING';
const ERROR = 'ERROR';
const RESET = 'RESET';
const PUT_EDITAR_UBICACION = 'PUT_EDITAR_UBICACION';
const PUT_EDITAR_CONTARSENA = 'PUT_EDITAR_CONTRASENA';
const PUT_EDITAR_AVATAR = 'PUT_EDITAR_AVATAR';
// reducer
export default function profileReducer(state = dataProfils, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, message: '', error: '', loading: true};
    case RESET:
      return {
        ...state,
        message: '',
        loading: false,
        error: '',
      };
    case ERROR: // TODO
      return { ...state, message: '', loading: false, error: action.payload.error };
    case PUT_EDITAR_UBICACION:
      return {
        ...state,
        message: "Coordenadas Cambiadas Satisfactoriamente",
        loading: false,
        error: '',
      };
    case PUT_EDITAR_AVATAR:
      return {
        ...state,
        message: "Avatar Cambiado Satisfactoriamente",
        loading: false,
        error: '',
      };
    case PUT_EDITAR_CONTARSENA:
      return {
        ...state,
        message: "Password Cambiado Satisfactoriamente",
        loading: false,
        error: '',
      };
    default:
      return state;
  }
}

// // acciones

export const getPassUbic = () => async (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const putPassword = (token,users) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
    const { code, passold, passnew, passnewconfirm } = users;
    const data = { id:code, passnew, passold, passnewconfirm };
    const config = {
      method: 'put',
      url: `${BaseUrl}/api/auth/password`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data:{data},
    };
    const res = await axios(config);
    dispatch({
      type: PUT_EDITAR_CONTARSENA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

export const putUbicacion = (token,users) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const { code, latitud, longitud } = users;
    const data = { id:code, latitud, longitud };

    const config = {
      method: 'put',
      url: `${BaseUrl}/api/auth/latlong`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data:{data},
    };
    const res = await axios(config);
    dispatch({
      type: PUT_EDITAR_UBICACION
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

export const putAvatar = (token,users) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const { code, avatar } = users;
    const data = { id:code, avatar };
    const config = {
      method: 'put',
      url: `${BaseUrl}/api/auth/avatar`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data:{data},
    };
    const res = await axios(config);
    dispatch({
      type: PUT_EDITAR_AVATAR
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};
