import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  styled,
  Paper,
  Stack,
  Card,
  Grid,
  Container,
  Avatar,
  Divider,
  Typography,
  Switch,
  TextField,
  FormControlLabel,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import validator from 'validator';
import Iconify from '../../components/iconify';
import { postOrganization } from '../../redux/organizationDucks';

const style = {
  width: 500,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const CreateOrganizationPage = ({token,usuario}) => {
  const dispatch = useDispatch();

  const load = useSelector((store) => store.organization.loadingOrg);
  const err = useSelector((store) => store.organization.errorOrg);
  const org = useSelector((store) => store.organization.Org);
 
  const [loading, setLoading] = useState(true);
  const [error,setError] = useState('')

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('../organization');
  };

  useEffect(() => {
    if ( err==='') {
      setLoading(false);
      setError(err)
    }else{
      setError(err)
      setLoading(false)
    }
  }, [err]);

  useEffect(() => {
    if (org) navigate('../organization');
  }, [load]);



  const imagen = '/assets/images/tekchile/logoTek_top.png';
  const [img, setImg] = useState(imagen);

  const [code, setCode] = useState('');
  const handleChangeCode = (event) => {
    if (event.target.value.length < 20) {
      setCode(event.target.value);
    }
  };

  const [name, setName] = useState('');
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const [url, setUrl] = useState('/assets/images/tekchile/logoTek_top.png');
  const handleChangeUrl = (event) => {
    setUrl(event.target.value);
    if (validator.isURL(url)) {
      setImg(url);
    } else {
      setImg(imagen);
    }
  };

  useEffect(() => {
    if (validator.isURL(url)) {
      setImg(url);
    } else {
      setImg(imagen);
    }
  }, [url]);

  const [latitud, setLatitud] = useState(-38.737458774894144);
  const handleChangeLat = (event) => {
    setLatitud(event.target.value);
  };

  const [longitud, setLongitud] = useState(-72.5966802869623);
  const handleChangeLong = (event) => {
    setLongitud(event.target.value);
  };

  const [status, setStatus] = useState(true);
  const handleChangeStatus = (event) => {
    setStatus(event.target.checked);
  };

  const handleSaveOrganization = async (e) => {
    e.preventDefault();
    setLoading(true);
    const organizationItem = {
      code,
      name,
      logo: url,
      status,
      latitud,
      longitud,
    };
    // setError(false)
    dispatch(postOrganization(token,organizationItem,usuario));
  };

  return (
    <Container sx={style}>
      <Stack direction={'row'} spacing={2} alignItems="center">
        <Typography variant="h5" gutterBottom>
          Create Organization
        </Typography>
      </Stack>
      <Card>
        {err !== '' && (
          <Stack sx={{ width: '100%' }} spacing={2} p={1}>
            <Alert severity="error">{err}</Alert>
          </Stack>
        )}
        <form onSubmit={handleSaveOrganization}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} p={1}>
            <Grid item xs={6}>
              <Item>
                <Avatar alt="Logo" variant="rounded" src={img} sx={{ width: 200, height: 100 }} />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={'column'} justifyContent="center" alignItems="center">
                <Item>
                  <TextField
                    required
                    id="outlined-code"
                    label="Code"
                    value={code}
                    size="small"
                    onChange={handleChangeCode}
                  />
                </Item>
                <Item>
                  <TextField
                    required
                    id="outlined-name"
                    label="Name"
                    value={name}
                    size="small"
                    onChange={handleChangeName}
                  />
                </Item>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  id="outlined-latitud"
                  label="Latitud"
                  size="small"
                  onChange={handleChangeLat}
                  type="text"
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  id="outlined-longitud"
                  label="Longitud"
                  size="small"
                  onChange={handleChangeLong}
                  type="text"
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  id="outlined-url"
                  label="Logo Url"
                  size="small"
                  type="url"
                  onChange={handleChangeUrl}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'}>
                  <FormControlLabel
                    control={<Switch color="primary" checked={status} onChange={handleChangeStatus} />}
                    label={status ? 'Operativo' : 'No Operativo'}
                  />
                </Stack>
              </Item>
            </Grid>
          </Grid>

          <Divider />

          <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'}>
            <Item>
              <LoadingButton size="medium" variant="contained" onClick={handleNavigate} color="error">
                <Iconify icon={'akar-icons:arrow-back-thick'} sx={{ mr: 0.5 }} />
                Back
              </LoadingButton>
            </Item>
            <Item>
              <LoadingButton size="medium" variant="contained" type="submit" loading={loading} color="secondary">
                <Iconify icon={'fluent:save-edit-20-regular'} sx={{ mr: 0.5 }} />
                Save
              </LoadingButton>
            </Item>
          </Stack>
        </form>
      </Card>
    </Container>
  );
};

export default CreateOrganizationPage;
