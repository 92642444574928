import { useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet,useRoutes } from 'react-router-dom';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import LoginPage from './pages/Auth/LoginPage';
import Page404 from './pages/Page404';
import DashboardAppPage from './pages/Dashboard/DashboardAppPage';
import {TrafficligthPage,
        CreateTrafficligthPage,
        EditTrafficligthPage,
        DeleteTrafficligthPage,
        OrganizationPage,
        CreateOrganizationPage,
        EditOrganizationPage,
        DeleteOrganizationPage,
        UserPage,
        DeleteUserPage,
        CreateUserPage,
        EditUserPage,
        LogsPage,
        MapsPage,
      DetailsPage,
    ProfilePage } 
        from './pages';

import { getValidate, userAuth } from './redux/authDucks';


// ----------------------------------------------------------------------

export default function Router() {

  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem('usuario'));
  const token = JSON.parse( sessionStorage.getItem('authToken'));
  const userAuthRedux = useSelector(store=>store.auth.userAuth);
  const tokenAuthRedux = useSelector(store=>store.auth.tokenAuth);
  const errorAuthRedux = useSelector(store=>store.auth.errorAuth);

  useEffect(()=>{
    dispatch(userAuth());
  },[dispatch])
  
  const AuthProvider = ({ ruta='/'})=>{
    dispatch(getValidate(user,token))
    if( userAuthRedux && tokenAuthRedux ){
      if(errorAuthRedux==='' )
        return <Outlet/>;
      return <Navigate to={ruta}/>;  
    }
      return <Navigate to={ruta}/>;  
  };

  const AuthProviderAdmin = ({ ruta='/'})=>{
    dispatch(getValidate(user,token))
    if( userAuthRedux && tokenAuthRedux ){
      if(errorAuthRedux==='' && (userAuthRedux.roles==='admin'||userAuthRedux.roles==='Sadmin'))
        return <Outlet/>;
      return <Navigate to={'/dashboard/app'}/>;  
    }
      return <Navigate to={ruta}/>;  
  };

  const AuthProviderSAdmin = ({ ruta='/'})=>{
    dispatch(getValidate(user,token))
    if( userAuthRedux && tokenAuthRedux ){
      if(errorAuthRedux==='' && (userAuthRedux.roles==='Sadmin'))
        return <Outlet/>;
      return <Navigate to={'/dashboard/app'}/>;  
    }
      return <Navigate to={ruta}/>;  
  };

  const routes = useRoutes([
    {
      element:<AuthProvider/>,
      children:[
        {
          path: '/dashboard',
          element: <DashboardLayout usuario={userAuthRedux}/>,
          children: [
            { element: <Navigate to="/app" />, index: true },
            { path: 'app', element: <DashboardAppPage token={tokenAuthRedux} usuario={userAuthRedux} /> },
            { path: 'logs', element: <LogsPage  token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: 'details', element: <DetailsPage  token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: 'maps', element: <MapsPage  token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: 'profile', element: <ProfilePage  token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: '404', element: <Page404  token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path:'*', element: <Navigate to="404" replace /> },
          ],
        }
      ]
    },   
    {
      element:<AuthProviderAdmin usuario={userAuthRedux}/>,
      children:[
        // Usuarios administradores
        {
          path: '/admin',
          element: <DashboardLayout usuario={userAuthRedux}  />,
          children: [
            { element: <Navigate to="/organization" />, index: true },
            { path: 'organization', element: <OrganizationPage token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: 'organizationcreate', element: <CreateOrganizationPage token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: 'organizationedit', element: <EditOrganizationPage token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: 'trafficligth', element: <TrafficligthPage token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: 'trafficligthcreate', element: <CreateTrafficligthPage token={tokenAuthRedux} usuario={userAuthRedux} /> },
            { path: 'trafficligthedit', element: <EditTrafficligthPage token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: '404', element: <Page404 usuario={userAuthRedux} /> },
            { path:'*', element: <Navigate to="404" replace/> },
          ],
        },
      ]
    }
    ,
    {
      element:<AuthProviderSAdmin user={userAuthRedux}/>,
      children:[
        // Usuarios administradores
        {
          path: '/admin',
          element: <DashboardLayout usuario={userAuthRedux}  />,
          children: [
            { element: <Navigate to="/user" />, index: true },
            { path: 'user', element: <UserPage token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path:'usercreate', element:<CreateUserPage token={tokenAuthRedux} usuarios={userAuthRedux}/>},
            { path:'useredit', element:<EditUserPage token={tokenAuthRedux} usuario={userAuthRedux}/>},
            { path:'userdelete', element:<DeleteUserPage token={tokenAuthRedux} usuario={userAuthRedux}/>},
            { path: 'organizationdelete', element: <DeleteOrganizationPage token={tokenAuthRedux} usuario={userAuthRedux}/> },
            { path: 'trafficligthdelete', element: <DeleteTrafficligthPage token={tokenAuthRedux} usuario={userAuthRedux}/> },       
            { path: '404', element: <Page404 usuario={userAuthRedux} /> },
            { path:'*', element: <Navigate to="404" replace/> },
          ],
        }]
    }
    ,
    {
        path: '/',
        element: <LoginPage />, 
        children:[
          {element: <LoginPage />, index:true}
        ] 
    }
    ,
    {
      path:'*',
      element: <SimpleLayout/>,
      children: [
        { element: <Navigate to="/" />, index: true },
        { path: '404', element: <Page404 usuario={userAuthRedux}/> },
        { path:'*', element: <Navigate to="404" replace/> },
     
      ],
    }

  ]);

  return  routes;
  
    // return !loadingAuthRedux && routes ;
}

