import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { filter } from 'lodash';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  TablePagination,
  IconButton,
  Stack,
  Typography,
  Paper,
} from '@mui/material';

import { useDispatch } from 'react-redux';

import Moment from 'react-moment';

import { AssignmentOutlined, Download, TrafficOutlined } from '@mui/icons-material';
import Label from '../../../components/label';
import ToolbarCard from './ToolbarCard';
import { getLogs, postExport } from '../../../redux/logDucks';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CardContentDetails({ user, token, LOGLIST, headLabel, nameTraffic }) {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [orderBy, setOrderBy] = useState('createdAt');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const filteredUsers = applySortFilter(LOGLIST, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;
  const [open, setOpen] = useState(true);

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        {/* <HeadCard/> */}
        <ToolbarCard filterName={filterName} select={'details'} onFilterName={handleFilterByName} page={state.page} />
        <Divider />
        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const { id, createdAt, updatedAt, statustrafficligth, comment } = row;
          const deltaTime = new Date(updatedAt) - new Date(createdAt);
          let periodo;
          const segundos = Math.floor(deltaTime / 1000);
          const minutos = Math.floor(segundos / 60);
          const horas = Math.floor(minutos / 60);
          const dias = Math.floor(horas / 24);

          if (deltaTime / 3600000 > 24) periodo = `${dias} Dias y ${horas % 24} Hrs`;
          if (deltaTime / 3600000 < 24) periodo = `${horas % 24} Hrs y ${minutos % 60} Min`;
          if (deltaTime / 60000 < 60) periodo = `${minutos % 60} Min`;
          if (deltaTime / 1000 < 60) periodo = `${segundos % 60} Seg`;
          return (
            <List key={id}>
              <ListItem>
                <Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">ID:</Typography>
                    <ListItemText primary={id} />
                  </Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">Name:</Typography>
                    <ListItemText primary={nameTraffic} />
                  </Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">Status:</Typography>
                    <ListItemText
                      primary={
                        <Label
                          sx={{ p: 1.5 }}
                          color={
                            statustrafficligth === '101111'
                              ? 'info'
                              : statustrafficligth === '110111' || statustrafficligth === '10111'
                              ? 'primary'
                              : statustrafficligth === '111011' || statustrafficligth === '11011'
                              ? 'default'
                              : statustrafficligth === '111101' || statustrafficligth === '11101'
                              ? 'error'
                              : statustrafficligth === '111110' || statustrafficligth === '11110'
                              ? 'warning'
                              : 'success'
                          }
                        >
                          <TrafficOutlined />
                          {statustrafficligth === '101111'
                            ? 'Se activo Auxiliar'
                            : statustrafficligth === '110111' || statustrafficligth === '10111'
                            ? 'Tiempo Excede 2 Hrs'
                            : statustrafficligth === '111011' || statustrafficligth === '11011'
                            ? 'Problemas con Empalme'
                            : statustrafficligth === '111101' || statustrafficligth === '11101'
                            ? 'Problemas en Control'
                            : statustrafficligth === '111110' || statustrafficligth === '11110'
                            ? 'Sin Luces hacia Terreno'
                            : 'Funcionamiento Normal'}
                        </Label>
                      }
                    />
                  </Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">Inicio:</Typography>
                    <ListItemText primary={<Moment format="DD/MM/YY HH:mm">{createdAt}</Moment>} />
                  </Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">Termino:</Typography>
                    <ListItemText primary={<Moment format="DD/MM/YY HH:mm">{updatedAt}</Moment>} />
                  </Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">Periodo:</Typography>
                    <ListItemText primary={periodo} />
                  </Stack>
                </Stack>
              </ListItem>
              <Divider />
            </List>
          );
        })}
        {isNotFound && (
          <List>
            <Paper
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" paragraph>
                Not found
              </Typography>

              <Typography variant="body2">
                No results found for &nbsp;
                <strong>&quot;{filterName}&quot;</strong>.
                <br /> Try checking for typos or using complete words.
              </Typography>
            </Paper>
          </List>
        )}
        <Divider />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={LOGLIST.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </nav>
    </Box>
  );
}
