import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar,  OutlinedInput, InputAdornment, Typography,Tooltip, IconButton,InputBase, Box, AppBar} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LeaderboardRounded } from '@mui/icons-material';
import { Stack } from '@mui/system';
// component
import Iconify from '../../../components/iconify';

import { postExport } from '../../../redux/logDucks';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 150,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 250,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));


// ----------------------------------------------------------------------

ToolbarCard.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  // select:PropTypes.array,
};

export default function ToolbarCard({filterName, onFilterName, select,page=0 }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate('../logs',{state:{page}});
  };
  return (
    <StyledRoot>
      {select === 'details' ? (
          <Tooltip title="Back">
              <IconButton  onClick={handleBack} color='primary'>
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
      ) : (
<>
      <Tooltip title='Traffic Events'>
          <LeaderboardRounded fontSize='large' sx={{mr:1}} color='error'/>
          </Tooltip>
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search..."
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />

</>
        
      )}
    </StyledRoot>
  );
}
