import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { filter } from 'lodash';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Divider,
  TablePagination,
  IconButton,
  Stack,
  Typography,
  Paper,
} from '@mui/material';

import { useDispatch } from 'react-redux';

import Moment from 'react-moment';

import { AssignmentOutlined, Download, TrafficOutlined } from '@mui/icons-material';
import Label from '../../../components/label';
import ToolbarCard from './ToolbarCard';
import { postExport } from '../../../redux/logDucks';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CardContent({ user, token, LOGLIST, headLabel,pageLocal }) {
  const dispatch = useDispatch();

  const [page, setPage] = useState(pageLocal || 0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState(user.roles === 'user' ? 'id' : 'organizationId');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const navigate = useNavigate();

  const handleDetails = (row) => {
    const { id, organizationId, name } = row;
    window.localStorage.setItem('pageState', JSON.stringify(page));
    navigate(`../details`, { state: { id, organizationId, name, page } });
  };

  const handleDownload = (trafficligth) => {
    const { id } = trafficligth;
    dispatch(postExport(token, id));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LOGLIST.length) : 0;

  const filteredUsers = applySortFilter(LOGLIST, getComparator(order, orderBy), filterName);
  const isNotFound = !filteredUsers.length && !!filterName;
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        {/* <HeadCard/> */}
        <ToolbarCard filterName={filterName} select={'log'} onFilterName={handleFilterByName} />
        <Divider />
        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
          const { id, organizationId, name, statustrafficligth, updatedAt } = row;
          return (
            <List key={id}>
              <ListItem
                secondaryAction={
                  <Stack>
                    <IconButton edge="end" aria-label="status">
                      <Label
                        sx={{ width: '30%', p: 2 }}
                        color={
                          statustrafficligth === '101111'
                          ? 'info'
                          :(statustrafficligth === '110111'||statustrafficligth === '10111')
                            ? 'primary'
                            : (statustrafficligth === '111011'||statustrafficligth === '11011')
                            ? 'default'
                             : (statustrafficligth === '111101'||statustrafficligth === '11101')
                            ? 'error'
                            : (statustrafficligth === '111110'||statustrafficligth === '11110')
                            ? 'warning'
                            : 'success'
                        }
                      >
                        <TrafficOutlined />
                      </Label>
                    </IconButton>
                    <IconButton edge="end" aria-label="view" onClick={() => handleDetails(row)}>
                      <AssignmentOutlined sx={{ color: 'blue' }} />
                    </IconButton>
                    {user.roles === 'admin' || user.roles === 'Sadmin' ? (
                      <IconButton edge="end" aria-label="download" onClick={() => handleDownload(row)}>
                        <Download sx={{ color: 'green' }} />
                      </IconButton>
                    ) : (
                      ''
                    )}
                  </Stack>
                }
              >
                <Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">Organización:</Typography>
                    <ListItemText primary={organizationId} />
                  </Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">UGAT ID:</Typography>
                    <ListItemText primary={id} />
                  </Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">Interseccion:</Typography>
                    <ListItemText primary={name} />
                  </Stack>
                  <Stack direction={'row'} spacing={2} alignContent={'center'} alignItems={'center'}>
                    <Typography variant="h6">Actualizacion:</Typography>
                    <ListItemText primary={<Moment format="DD/MM/YY HH:mm">{updatedAt}</Moment>} />
                  </Stack>
                </Stack>
              </ListItem>
              <Divider />
            </List>
          );
        })}
        {isNotFound && (
          <List>
            <Paper
              sx={{
                textAlign: 'center',
              }}
            >
              <Typography variant="h6" paragraph>
                Not found
              </Typography>

              <Typography variant="body2">
                No results found for &nbsp;
                <strong>&quot;{filterName}&quot;</strong>.
                <br /> Try checking for typos or using complete words.
              </Typography>
            </Paper>
          </List>
        )}
        <Divider />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={LOGLIST.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </nav>
    </Box>
  );
}
