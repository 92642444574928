import React from 'react'

import {
Box,
   Skeleton,
  } from '@mui/material';

const Loading = () => {
  return (
        <Box >
            <Skeleton variant="rectangular" sx={{bgcolor: 'grey.300' }} height={70}/>
            <Skeleton variant="rectangular" height={350}/>
            <Skeleton variant="rectangular" height={30} sx={{mt:"0.4rem",}}/>
        </Box>
  )
}

export default Loading