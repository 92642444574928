import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,
  Container
} from '@mui/material';
import { getLogs} from '../../redux/logDucks';

// components
import Alert from '../../components/alerts/Alerts';
// sections
import {CardContent, TableContent } from '../../sections/@dashboard/log';

// mock
import Loading from '../../components/loading/Loading';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Code', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'statustrafficligth', label: 'Estado', alignRight: false },
  { id: 'updatedAt', label: 'DateTime', alignRight: false },
  { id: 'details', label: 'Options', alignRight: false },
];
const TABLE_HEAD1 = [
  { id: 'organizationId', label: 'Organization', alignRight: false }, // TODO cambiar en BD registro por organizacion
  { id: 'id', label: 'Code', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'statustrafficligth', label: 'Estado', alignRight: false },
  { id: 'updatedAt', label: 'DateTime', alignRight: false },
  { id: 'details', label: 'Options', alignRight: false },
];

export default function LogPage({token,usuario}) {
  const dispatch = useDispatch();
  
  const LOGLIST = useSelector((store) => store.log.array);
  const load = useSelector((store) => store.log.loadingLog);
  const err = useSelector((store) => store.log.errorLog);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showTable, setShowTable] = useState(true);
  const [loading, setLoading] = useState(false);
  const user = usuario
// console.log(LOGLIST)
  useEffect(() => {
    dispatch(getLogs(token));
    setLoading(load);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(load);
    if (screenSize <= 770) {
      setShowTable(false);
    } else {
      setShowTable(true);
    }
  }, [screenSize,load]);

  const pageLocal = JSON.parse(window.localStorage.getItem('pageState'));

  return (
    <>
      <Helmet>
        <title> Logs | Cloud Tek </title>
      </Helmet>

      <Container>
        <Card>
          {loading ? (
            <>
              <Loading />
              <Alert openT={loading} message={"Load Information"}/>
            </>
          ) : ( err===''? (
            showTable ? 
              <TableContent 
                user={user}
                token={token}
                LOGLIST={LOGLIST}
                headLabel={user.roles === 'user' ? TABLE_HEAD : TABLE_HEAD1}
                pageLocal={pageLocal}
                />  
                : 
                <CardContent
                user={user}
                token={token}
                LOGLIST={LOGLIST}
                headLabel={user.roles === 'user' ? TABLE_HEAD : TABLE_HEAD1}
                pageLocal={pageLocal}
                />      
            ):(<Alert openT={err!==''} message={"Ups!!! Error al cargar el Log"}/>)
          )}
        </Card>
      </Container>
    </>
  );
}
