import axios from 'axios';

// constantes
const dataAuth = {
  loadingAuth: false,
  message:'',
  errorAuth: '',
  userAuth:null,
  tokenAuth:null
};


const BaseUrl = process.env.REACT_APP_URL_API;

const LOADING = 'LOADING_AUTH';
const ERROR = 'ERROR_AUTH';
const GET_OBTENER_TOKEN = 'GET_OBETENER_TOKEN';
const USER_ACTIVE = 'USER_ACTIVE';
const GET_OBTENER_VALIDATE = 'GET_OBETENER_VALIDATE';
const GET_LOGOUT = 'GET_LOGOUT';

// reducer
export default function authReducer(state = dataAuth, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, 
        loadingAuth: true, 
        errorAuth: '',
        message:'',
        userAuth:null,
        tokenAuth:null };
    case ERROR: // TODO
      return { ...state, 
        loadingAuth: false, 
        message:'',
        errorAuth: action.payload.error,
        userAuth:null,
        tokenAuth:null
      };
    case GET_OBTENER_TOKEN:
      return { ...state,
        errorAuth: '',
        message:`Welcome... ${action.payload[1].name}`,
        loadingAuth: false,
        userAuth:action.payload[1],
        tokenAuth:action.payload[0]
      };
    case GET_OBTENER_VALIDATE:
      return {
        ...state,
        message:'',
        loadingAuth: false,
        errorAuth: ''
      };
    case USER_ACTIVE:
      return{...state,
        loadingAuth:false,
        message:`Welcome... ${action.payload[1].name}`,
        errorAuth: "",
        userAuth:action.payload[1],
        tokenAuth:action.payload[0]
      };
    case GET_LOGOUT:
      return { ...state, 
        loadingAuth:false,
        message:'Succes Logout',
        errorAuth: '',
        userAuth:null,
        tokenAuth:null };
    default:
      return state;
  }
}

// acciones
// OBTENER INFORMACION
export const getAuth = (data) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
    const res = await axios.post(`${BaseUrl}/api/auth/login`, data={data});
    if(res.data.payload[0] || res.data.payload[1]){
 
      sessionStorage.setItem('usuario',JSON.stringify(res.data.payload[0].user));
      sessionStorage.setItem('authToken',JSON.stringify(res.data.payload[1].token));

        dispatch({
          type:GET_OBTENER_TOKEN,
          payload: [res.data.payload[1].token,res.data.payload[0].user]
        })
    } 
  } catch (error) {
    sessionStorage.clear();
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

export const getValidate = (user,tokenExist) => async (dispatch) => {
  try {
    // dispatch({
    //   type: LOADING,
    // });
    const config = {
      method: 'post',
      url: `${BaseUrl}/api/auth/validated`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': tokenExist,
      },
      data:{data:user},
    };
    const res = await axios(config);
    sessionStorage.setItem('usuario',JSON.stringify(res.data.payload[0]));
    sessionStorage.setItem('authToken',JSON.stringify(res.data.payload[1]));

    dispatch({
      type: GET_OBTENER_VALIDATE,
      payload: res.data,
    });
  } catch (error) {
    sessionStorage.clear();
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

export const userAuth = () => async (dispatch)=>{
  try {
    const user = JSON.parse(sessionStorage.getItem('usuario'));
    const token = JSON.parse( sessionStorage.getItem('authToken'));
    if(user && token)
      dispatch({
        type:USER_ACTIVE,
        payload: [token,user]
        })
  } catch (error) {
    sessionStorage.clear();
    dispatch({
      type:ERROR,
      payload:{error:'Sin Usuario Activo'}
    })
  }
 
}

export const getLogout = () => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
    sessionStorage.clear();
    dispatch({
      type: GET_LOGOUT,
    });
  } catch (error) {
    sessionStorage.clear();
    dispatch({
      type: ERROR,
      payload: {error:'Error de Logout'},
    });
  }
};
