import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
  Container,
  Typography,
  styled,
  Paper,
  TextField,
  Divider,
  Autocomplete,
  Alert,
  FormControlLabel,
  Grid,
  Switch,
  Stack,
  Avatar,
  Card,
  Checkbox,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import { putUser } from '../../redux/usersDucks';

const style = {
  width: 500,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'rigth',
  color: theme.palette.text.secondary,
}));

const EditUserPage = ({token,usuario}) => {
  const dispatch = useDispatch();

  const { state } = useLocation();

  const navigate = useNavigate();
  const load = useSelector((store) => store.user.loadingUser);
  const err = useSelector((store) => store.user.errorUser);
  const users = useSelector((store) => store.user.users);

  const [loading, setLoading] = useState(true);
  const [error,setError] = useState('')

  // Codigo para emular la carga desde BD
  useEffect(() => {
    if ( err==='') {
      setLoading(false);
      setError(err)
    }else{
      setError(err)
      setLoading(false)
    }
  }, [err]);

  useEffect(() => {
    if (users) navigate(-1);
  }, [load]);

  const logos = state?state.logo:[];
  const logoActual = logos.filter((item) => item.url === state.Organization.logo);

  const [logo, setLogo] = useState(logoActual[0]?logoActual[0]:({label:'Avatar1',url:'/assets/images/avatars/avatar_1.jpg'}));

  const roles1 = state?state.roles:[];
  const rolActual = roles1.filter((item) => item.label === state.Organization.rol);
  const [rol, setRol] = useState(rolActual[0]?rolActual[0]:{label:'user',rol:'user'});

  const [user, setUser] = useState(state?state.Organization.id:'');
  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };

  const [name, setName] = useState(state?state.Organization.name:'');
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const [password, setPassword] = useState('');
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const [email, setEmail] = useState(state?state.Organization.email:'');
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const [phone, setPhone] = useState(state?state.Organization.phone:'');
  const handleChangePhone = (event) => {
    setPhone(event.target.value);
  };

  const [status, setStatus] = useState(state?state.Organization.status:false);
  const handleChangeStatus = (event) => {
    setStatus(event.target.checked);
  };

  const [notificationP, setNotificationP] = useState(
    state?
    (state.Organization.notification === 2 || state.Organization.notification === 3):
    false
  );
  const handleChangeNotificationP = (event) => {
    setNotificationP(event.target.checked);
  };
  const [notificationE, setNotificationE] = useState(
    state?
    (state.Organization.notification === 1 || state.Organization.notification === 3):
    false
  );
  const handleChangeNotificationE = (event) => {
    setNotificationE(event.target.checked);
  };

  const org1 = state?state.ListOrg:[];
  // const result = JSON.parse(state.Organization.organization);

  const [prueba, setPrueba] = useState([org1[0]]);

  const handleNavigate = () => {
    navigate(-1);
  };

  const handleSaveUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    const userItem = {
      id: user,
      name,
      logo: logo.url,
      organization: prueba.map(orgs=>orgs.label),
      notification: notificationE && notificationP ? 3 : notificationP ? 2 : notificationE ? 1 : 0,
      email,
      phone,
      rol: rol.label,
      status,
      password,
      createdBy: state.Organization.createdBy
    };
    //  console.log(userItem)
    dispatch(putUser(token,userItem,usuario));
  };
  return (
    <Container sx={style}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Edit User
        </Typography>
      </Stack>
      <Card>
        <form onSubmit={handleSaveUser}>
          {error !== '' && (
            <Stack sx={{ width: '100%' }} spacing={2} p={1}>
              <Alert severity="error">{error}</Alert>
            </Stack>
          )}
          {
            state ? 
             state.ListOrg.length === 0 ? (
              <Stack sx={{ width: '100%' }} spacing={2} p={1} mt={2} mb={2}>
                <Alert severity="warning">Debe Existir una Organizacion antes de Editar el Usuario</Alert>
              </Stack>
            ):          
            ( <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={6}>
              <Stack direction={'row'} justifyContent="center" alignItems="center">
                <Item sx={{ alignItem: 'center' }}>
                  <Avatar alt={name} src={logo.url} sx={{ width: 70, height: 70 }} />
                </Item>
                <Item>
                  <TextField
                    disabled
                    id="outlined-code"
                    label="User"
                    value={user}
                    size="small"
                    onChange={handleChangeUser}
                  />
                </Item>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Item sx={{ mt: 2 }}>
                <Autocomplete
                  // disablePortal
                  disabled={!state}
                  id="combo-box-demo"
                  value={logo}
                  onChange={(event, newValue) => {
                    if (newValue != null) {
                      setLogo(newValue);
                    }
                  }}
                  options={logos}
                  size="small"
                  sx={{ maxWidth: '100%' }}
                  renderInput={(params) => <TextField {...params} required label="Logo" />}
                />
              </Item>
            </Grid>

            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  disabled={!state}
                  id="outlined-name"
                  label="Name"
                  value={name}
                  size="small"
                  onChange={handleChangeName}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Autocomplete
                  disabled={!state}
                  disablePortal
                  id="combo-box-demo_a"
                  value={rol}
                  onChange={(event, newValue) => {
                    if (newValue!==null) 
                    setRol(newValue);
                  }}
                  options={roles1}
                  size="small"
                  sx={{ maxWidth: '100%' }}
                  renderInput={(params) => <TextField {...params} required label="Roles" />}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={'column'} alignContent="center" alignItems={'center'}>
                <Item>
                  <TextField
                    required
                    disabled={!state}
                    id="outlined-latitud"
                    label="Password"
                    // value={latitud}
                    size="small"
                    onChange={handleChangePassword}
                    type="password"
                  />
                </Item>
                <Item>
                  <TextField
                    required
                    disabled={!state}
                    id="outlined-latitud_em"
                    label="Email"
                    value={email}
                    size="small"
                    onChange={handleChangeEmail}
                    type="email"
                  />
                </Item>
                <Item>
                  <TextField
                    required
                    disabled={!state}
                    id="outlined-longitud"
                    label="Phone"
                    size="small"
                    value={phone}
                    onChange={handleChangePhone}
                    type="tel"
                  />
                </Item>
                <Item>
                  <FormControlLabel
                    disabled={!state}
                    control={<Switch color="primary" checked={status} onChange={handleChangeStatus} />}
                    label={status ? 'Operativo' : 'No Operativo'}
                  />
                </Item>
                <Item>
                  <FormControlLabel
                    disabled={!state}
                    control={<Checkbox onChange={handleChangeNotificationE} checked={notificationE} />}
                    label="Email"
                  />
                  <FormControlLabel
                    disabled={!state}
                    control={<Checkbox onChange={handleChangeNotificationP} checked={notificationP} />}
                    label="SMS"
                  />
                </Item>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Item>
                {state && 
                (<Autocomplete
                  multiple
                  disabled={rol.label === 'admin' || rol.label === 'Sadmin'||!state}
                  id="tags-standard"
                  sx={{ maxWidth: '100%' }}
                  options={state?state.ListOrg:[]}
                  getOptionLabel={(option) => option.label?option.label:''}
                  defaultValue={[org1[0]]}
                  onChange={(event, newValue) => {
                    setPrueba(newValue);
                    console.log(prueba);
                  }}
                  renderInput={(params) => <TextField {...params} variant="standard" label="Organizaciones" />}
                />
                )}
              </Item>
            </Grid>
          </Grid>)
          :  <Stack sx={{ width: '100%' }} spacing={2} p={1} mt={2} mb={2}>
          <Alert severity="warning">Acceder desde Boton Editar</Alert>
        </Stack>
          }


          <Divider mt={2} mb={2} />

          <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'}>
            <Item>
              <LoadingButton size="medium" variant="contained" onClick={handleNavigate} color="error">
                <Iconify icon={'akar-icons:arrow-back-thick'} sx={{ mr: 0.5 }} />
                Back
              </LoadingButton>
            </Item>
            <Item>
              {
                state &&
                state.ListOrg.length!==0 &&
                <LoadingButton size="medium" variant="contained" disabled={state.ListOrg.length===0} type="submit" loading={loading} color="secondary">
                <Iconify icon={'fluent:save-edit-20-regular'} sx={{ mr: 0.5 }} />
                Save
              </LoadingButton>
              }

            </Item>
          </Stack>
        </form>
      </Card>
    </Container>
  );
};

export default EditUserPage;
