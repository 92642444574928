import * as React from 'react';
import { useEffect, useState,useMemo } from 'react';
import { Helmet } from 'react-helmet-async';


import { Container } from '@mui/system';

import { useDispatch, useSelector } from 'react-redux';

import Markers from '../../components/markers';
import ToolbarMaps from '../../sections/@dashboard/maps/ToolbarMaps';
import { getMap } from '../../redux/mapDucks';

const Maps = ({usuario,token}) => {
  const [ejecutar, setEjecutar] = useState(1);
  const dispatch = useDispatch();

  const hora = new Date();
  let TRAFFICLIGTHLIST = useSelector((store) => store.map.array[0]);
  let TotalTrafficOnline = useSelector((store) => store.map.array[1]);
  let TotalTrafficAuxiliar = useSelector((store) => store.map.array[2]);
  let TotalTrafficOffline = useSelector((store) => store.map.array[3]);
  let TotalTrafficEmpalme = useSelector((store) => store.map.array[4]);
  let TotalTrafficControl = useSelector((store) => store.map.array[5]);
  let TotalTrafficLuces = useSelector((store) => store.map.array[6]);
  
  TRAFFICLIGTHLIST=useMemo(()=>TRAFFICLIGTHLIST);
  TotalTrafficAuxiliar=useMemo(()=>TotalTrafficAuxiliar);
  TotalTrafficOnline=useMemo(()=>TotalTrafficOnline);
  TotalTrafficOffline=useMemo(()=>TotalTrafficOffline);
  TotalTrafficEmpalme=useMemo(()=>TotalTrafficEmpalme);
  TotalTrafficControl=useMemo(()=>TotalTrafficControl);
  TotalTrafficLuces=useMemo(()=>TotalTrafficLuces);
  TotalTrafficLuces = useMemo(()=>TotalTrafficLuces);

  useEffect(() => {
    dispatch(getMap(token));
  }, [dispatch]);

  useEffect(() => {
    console.log("Updated ",hora.toLocaleTimeString());
    dispatch(getMap(token));
    setTimeout(() => {
      setEjecutar(ejecutar + 1);
    }, 5000);
  }, [ejecutar]);


  return (
    <>
      <Helmet>
        <title> Maps | Tek Cloud </title>
      </Helmet>
      <Container>

       <ToolbarMaps       
            user={usuario}
            TRAFFICLIGTHLIST= {TRAFFICLIGTHLIST}
            TotalTrafficOnline ={TotalTrafficOnline}
            TotalTrafficAuxiliar={TotalTrafficAuxiliar}
            TotalTrafficOffline = {TotalTrafficOffline}
            TotalTrafficEmpalme ={TotalTrafficEmpalme}
            TotalTrafficControl = {TotalTrafficControl}
            TotalTrafficLuces = {TotalTrafficLuces} />
        <Markers 
            user={usuario}
            TotalTrafficOnline ={TotalTrafficOnline}
            TotalTrafficAuxiliar={TotalTrafficAuxiliar}
            TotalTrafficOffline = {TotalTrafficOffline}
            TotalTrafficEmpalme ={TotalTrafficEmpalme}
            TotalTrafficControl = {TotalTrafficControl}
            TotalTrafficLuces = {TotalTrafficLuces}
            />
            
      </Container>
    </>
  );
};

export default Maps;
