import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import usuariosReducer from './usersDucks';
import organizationReducer from './organizationDucks';
import trafficligthReducer from './trafficligthDucks';
import logReducer from './logDucks';
import mapReducer from './mapDucks';
import authReducer,{userAuth,getValidate} from './authDucks';
import profileReducer from './profileDucks';

const rootReducer = combineReducers({
  log: logReducer,
  map: mapReducer,
  user: usuariosReducer,
  trafficligth: trafficligthReducer,
  organization: organizationReducer,
  profile: profileReducer,
  auth: authReducer,
});

export default function generateStore() {

  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
  // getValidate(JSON.parse(sessionStorage.getItem('usuario')),JSON.parse( sessionStorage.getItem('authToken')))(store.dispatch);
  userAuth()(store.dispatch);
  // deberia validar los datos de autenticacion
  return store;
}
