import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import 'moment-timezone';

// @mui
import {
  Card,
  Stack,
  Container,
  Typography,
} from '@mui/material';

import { getDetails } from '../../redux/logDucks';
import Alert from '../../components/alerts/Alerts';
// components
import {TableContentDetails,CardContentDetails } from '../../sections/@dashboard/log';

// mock
import Loading from '../../components/loading/Loading';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'trafficligthId', label: 'Nombre', alignRight: false },
  { id: 'statustrafficligth', label: 'Estado', alignRight: false },
  { id: 'createdAt', label: 'Start_Events', alignRight: false },
  { id: 'updatedAt', label: 'End_Events', alignRight: false },
  { id: 'comment', label: 'Notas', alignRight: false },
  { id: 'periodo', label: 'Time_Events', alignRight: false }, // TODO cambiar en BD registro por organizacion
];

// ----------------------------------------------------------------------


export default function DeatilsPage({token}) {
  const dispatch = useDispatch();

  const user =[];
  const { state } = useLocation();

  const code = state ? state.id:'';
  const org = state ? state.organizationId:'';
  const nameTraffic = state?state.name:'';

  const LOGLIST = useSelector((store) => store.log.details);
  const load = useSelector((store) => store.log.loadingLog);
  const err = useSelector((store) => store.log.errorLog);

  const [loading, setLoading] = useState(true);

  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [showTable, setShowTable] = useState(true);

  useEffect(() => {
      dispatch(getDetails(token,code));
      setLoading(load);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(load);
    if (screenSize <= 770) {
      setShowTable(false);
    } else {
      setShowTable(true);
    }
  }, [screenSize,load]);

  return (
    <>
      <Helmet>
        <title> Details | Cloud Tek </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h4" gutterBottom>
            Details
          </Typography>
        </Stack>
        <Card>

          {loading ? (
            <>
              <Loading />
              <Alert openT={loading} message={'Load Datails'} />
            </>
          ) : err===''? (
            showTable ? 
            <TableContentDetails 
              user={user}
              token={token}
              LOGLIST={LOGLIST}
              headLabel={TABLE_HEAD }
              nameTraffic={nameTraffic}
              />  
              : 
              <CardContentDetails
              user={user}
              token={token}
              LOGLIST={LOGLIST}
              headLabel={ TABLE_HEAD}
              nameTraffic={nameTraffic}
              />  
            )
            :(<Alert openT={err!==''} message={"Ups!!! Error al cargar los Detalles"}/>)}
        </Card>
      </Container>
    </>
  );
}
