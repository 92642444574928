import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  MenuItem,
  styled,
  Paper,
  Typography,
  TextField,
  Divider,
  Alert,
  FormControlLabel,
  Switch,
  Container,
  Stack,
  Card,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import { getCodeOrganization, deleteOrganization } from '../../redux/organizationDucks';

const style = {
  width: 500,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'rigth',
  color: theme.palette.text.secondary,
}));

const DeleteOrganizationPage = ({token}) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = useLocation();

  const load = useSelector((store) => store.organization.loadingOrg);
  const err = useSelector((store) => store.organization.errorOrg);
  const orga = useSelector((store) => store.organization.Org);
 
  const [loading, setLoading] = useState(true);
  const [error,setError] = useState('')

  useEffect(() => {
    if ( err==='') {
      setLoading(false);
      setError(err)
    }else{
      setError(err)
      setLoading(false)
    }
  }, [err]);

  useEffect(() => {
    if (orga) {
      navigate('../organization');}
  }, [load]);

  const orgId = state? state.Organization.id:''
  const handleDelete = () => {
    dispatch(deleteOrganization(token,orgId));
  };
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <Container sx={style}>
      <Stack direction={'row'} spacing={2} alignItems="center" mb={3}>
        <Typography variant="h5" gutterBottom>
          Edit Organization
        </Typography>
      </Stack>
      <Card>
        <Stack spacing={1} justifyContent="center" alignItems="center" textAlign={'center'}>
          <Item>
            {error !== '' && (
              <Stack sx={{ width: '100%' }} spacing={2} p={1}>
                <Alert severity="error">{error}</Alert>
              </Stack>
            )}
          </Item>

          <Typography variant="h6" gutterBottom>
            Are Sure Delete ?
          </Typography>
          <Item>
            <Typography variant="h7" gutterBottom>
              <strong> Name: {state?state.Organization.name:'Null Organization'} </strong>
            </Typography>
          </Item>
        </Stack>
        <Divider />
        <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'} mb={2}>
          <Item>
            <LoadingButton size="medium" variant="contained" onClick={handleNavigate} color="error">
              <Iconify icon={'akar-icons:arrow-back-thick'} sx={{ mr: 0.5 }} />
              Back
            </LoadingButton>
          </Item>
          <Item>
            {
              state &&
              <LoadingButton size="medium" variant="contained" disabled={!state} color="secondary" loading={loading} onClick={handleDelete}>
              <Iconify icon={'fluent:delete-28-regular'} sx={{ mr: 0.5 }} />
              Delete
            </LoadingButton>
            }

          </Item>
        </Stack>
      </Card>
    </Container>
  );
};

export default DeleteOrganizationPage;
