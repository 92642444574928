import { Helmet } from 'react-helmet-async';
import { Link as RouterLink,useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  // minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404({usuario}) {

  const navigate = useNavigate();

  const handleBack = ()=>{
    navigate(-1);
  }
  return (
    <>
      <Helmet>
        <title> 404 Not Found | Cloud Tek </title>
      </Helmet>

      <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
        <Typography variant="h3" paragraph>
          Sorry, page not found!
        </Typography>

        <Typography sx={{ color: 'text.secondary' }}>
          Lo siento, la Url ingresada no fue encontrada favor volver a pagina principal
        </Typography>

        <Box
          component="img"
          src="/assets/illustrations/illustration_404.svg"
          sx={{ height: 120, mx: 'auto', my: { xs: 5, sm: 10 } }}
        />

        <Button to="/dashboard/app" size="large" variant="contained" onClick={handleBack}>
          Go to {!usuario?'Login':'Dashboard'}
        </Button>
      </StyledContent>
    </>
  );
}
