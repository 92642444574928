import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import validator from 'validator';
import {
  styled,
  Paper,
  Typography,
  Container,
  Avatar,
  Grid,
  TextField,
  Divider,
  Alert,
  FormControlLabel,
  Switch,
  Stack,
  Card,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import { getCodeOrganization, putOrganization } from '../../redux/organizationDucks';

const style = {
  width: 500,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'rigth',
  color: theme.palette.text.secondary,
}));

const EditOrganizationPage = ({token,usuario}) => {
  const dispatch = useDispatch();

  const { state } = useLocation();
  const navigate = useNavigate();

  const OrganizationEdit = useSelector((store) => store.organization.array[0]);
  const [org, setOrg] = useState(OrganizationEdit);

  const load = useSelector((store) => store.organization.loadingOrg);
  const err = useSelector((store) => store.organization.errorOrg);
  const orga = useSelector((store) => store.organization.Org);
 
  const [loading, setLoading] = useState(true);
  const [error,setError] = useState('')

  useEffect(() => {
    if ( err==='') {
      setLoading(false);
      setError(err)
    }else{
      setError(err)
      setLoading(false)
    }
  }, [err]);

  useEffect(() => {
    if (orga) {
      navigate('../organization');}
  }, [load]);


  const [code, setCode] = useState(state?state.Organization.id:'');

  const [name, setName] = useState(state?state.Organization.name:'');
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const imagen = '/assets/images/tekchile/logoTek_top.png';
  const [img, setImg] = useState(imagen);
  const [url, setUrl] = useState(state?state.Organization.logo:'');
  const handleChangeUrl = (event) => {
    setUrl(event.target.value);
  };

  useEffect(() => {
    if (validator.isURL(url)) {
      setImg(url);
    } else {
      setImg(imagen);
    }
  }, [url]);

  const [latitud, setLatitud] = useState(state?state.Organization.latitud:'');
  const handleChangeLat = (event) => {
    setLatitud(event.target.value);
  };

  const [longitud, setLongitud] = useState(state?state.Organization.longitud:'');
  const handleChangeLong = (event) => {
    setLongitud(event.target.value);
  };

  const [status, setStatus] = useState(state?state.Organization.status:false);
  // const [status, setStatus] = useState(true);
  const handleChangeStatus = (event) => {
    setStatus(event.target.checked);
    // console.log(event.target.checked)
  };

  const updatedBy = state?state.Organization.updatedBy:'';
  const createdBy = state?state.Organization.createdBy:'';

  const handleSaveOrganization = async (e) => {
    e.preventDefault();
    const organizationItem = {
      id:code,
      name,
      logo: url,
      status,
      latitud,
      longitud,
      createdBy,
      updatedBy,
    };
    dispatch(putOrganization(token,organizationItem,usuario));
  };

  const handleNavigate = () => {
    navigate('../organization');
  };
  return (
    <Container sx={style}>
      <Stack direction={'row'} spacing={2} alignItems="center">
        <Typography variant="h5" gutterBottom>
          Edit Organization
        </Typography>
      </Stack>
      <Card>
        {error !== '' && (
          <Stack sx={{ width: '100%' }} spacing={2} p={1}>
            <Alert severity="error">{error}</Alert>
          </Stack>
        )}
        <form onSubmit={handleSaveOrganization}>
        {!state ? (
          <Stack sx={{ width: '100%' }} spacing={2} p={1}>
            <Alert severity="warning">Acceder desde Boton Editar</Alert>
          </Stack>
        ):
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} p={1}>
        <Grid item xs={6}>
          <Item>
            <Avatar alt="Logo" variant="rounded" src={img} sx={{ width: 200, height: 100 }} />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Stack spacing={1} direction={'column'} justifyContent="center" alignItems="center">
            <Item>
              <TextField disabled id="outlined-code" label="Code" value={code} size="small" />
            </Item>
            <Item>
              <TextField
                required
                disabled={!state}
                id="outlined-name"
                label="Name"
                value={name}
                size="small"
                onChange={handleChangeName}
              />
            </Item>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <TextField
              required
              disabled={!state}
              id="outlined-latitud"
              label="Latitud"
              value={latitud}
              size="small"
              onChange={handleChangeLat}
              type="number"
            />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <TextField
              required
              disabled={!state}
              id="outlined-longitud"
              label="Longitud"
              size="small"
              value={longitud}
              onChange={handleChangeLong}
              type="number"
            />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <TextField
              required
              disabled={!state}
              id="outlined-url"
              label="Logo Url"
              size="small"
              value={url}
              type="url"
              onChange={handleChangeUrl}
            />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'}>
              <FormControlLabel
              disabled={!state}
                control={<Switch color="primary" checked={status} onChange={handleChangeStatus} />}
                label={status ? 'Operativo' : 'No Operativo'}
              />
            </Stack>
          </Item>
        </Grid>
      </Grid>
        }
    

          <Divider />

          <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'}>
            <Item>
              <LoadingButton size="medium" variant="contained" onClick={handleNavigate} color="error">
                <Iconify icon={'akar-icons:arrow-back-thick'} sx={{ mr: 0.5 }} />
                Back
              </LoadingButton>
            </Item>
            <Item>
              {
                state &&
                <LoadingButton size="medium" variant="contained" type="submit" loading={loading} color="secondary">
                <Iconify icon={'fluent:save-edit-20-regular'} sx={{ mr: 0.5 }} />
                Save
              </LoadingButton>
              }

            </Item>
          </Stack>
        </form>
      </Card>
    </Container>
  );
};

export default EditOrganizationPage;
