import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Typography,
  styled,
  Paper,
  Stack,
  Card,
  Divider,
  Switch,
  TextField,
  Autocomplete,
  FormControlLabel,
  Avatar,
  Checkbox,
  Grid,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { postUser } from '../../redux/usersDucks';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar/Scrollbar';

const style = {
  width: 500,
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const CreateUserPage = ({token,usuarios}) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { state } = useLocation();
  const load = useSelector((store) => store.user.loadingUser);
  const err = useSelector((store) => store.user.errorUser);
  const usuario = useSelector((store) => store.user.users);
 
  const [loading, setLoading] = useState(true);
  const [error,setError] = useState('')

  const handleNavigate = () => {
    navigate(-1);
  };

  useEffect(() => {
    if ( err==='') {
      setLoading(false);
      setError(err)
    }else{
      setError(err)
      setLoading(false)
    }
  }, [err]);

  useEffect(() => {
    if (usuario) navigate(-1);
  }, [load]);

  const logos = state?state.logo:[];

  const [logo, setLogo] = useState(logos[0]?logos[0]:{label:'Avatar1',url:'/assets/images/avatars/avatar_1.jpg'});

  const roles1 = state?state.roles:[];
  const [rol, setRol] = useState(state?state.roles[3]:{label:'user',rol:'user'});

  const [user, setUser] = useState('');
  const handleChangeUser = (event) => {
    if (event.target.value.length < 20) {
      setUser(event.target.value);
    }
  };

  const [name, setName] = useState('');
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const [password, setPassword] = useState('');
  const handleChangePassword = (event) => {
    setPassword(event.target.value);
  };

  const [email, setEmail] = useState('');
  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const [phone, setPhone] = useState('');
  const handleChangePhone = (event) => {
    if (event.target.value.length <= 9) {
      setPhone(event.target.value);
    }
  };

  const [status, setStatus] = useState(true);
  const handleChangeStatus = (event) => {
    setStatus(event.target.checked);
  };

  const [notificationP, setNotificationP] = useState(false);
  const handleChangeNotificationP = (event) => {
    setNotificationP(event.target.checked);
  };
  const [notificationE, setNotificationE] = useState(false);
  const handleChangeNotificationE = (event) => {
    setNotificationE(event.target.checked);
  };

  const [prueba, setPrueba] = useState(state?[state.ListOrg[0]]:[]);

  const [organization, setOrganization] = useState(state?state.ListOrg[0]:[]);

  const handleSaveUser = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(prueba);
    const userItem = {
      code: user,
      name,
      logo: logo.url,
      organization: prueba.map(orgs=>orgs.label),
      notification: notificationE && notificationP ? 3 : notificationP ? 2 : notificationE ? 1 : 0,
      email,
      phone,
      rol: rol.label,
      status,
      password,
    };
    // console.log(userItem)
    dispatch(postUser(token , userItem, usuarios));
  };

  return (
    <Container sx={style}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h4" gutterBottom>
          Create User
        </Typography>
      </Stack>
      <Scrollbar>
        <Card>
          <form onSubmit={handleSaveUser}>
            {error !== '' && (
              <Stack sx={{ width: '100%' }} spacing={2} p={1}>
                <Alert severity="error">{error}</Alert>
              </Stack>
            )}
            {
            state ? (
              
                state.ListOrg.length === 0 ? (
                <Stack sx={{ width: '100%' }} spacing={2} p={1} mt={2} mb={2}>
                  <Alert severity="warning">No Existen Organizaciones Disponibles</Alert>
                </Stack>
              ):
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
              <Grid item xs={6}>
                <Stack direction={'row'} alignContent={'center'}>
                  <Item>
                    <Avatar alt={name} src={logo.url} sx={{ width: 70, height: 70 }} />
                  </Item>

                  <Item sx={{ mt: 2 }}>
                    <TextField
                      required
                      disabled={!state} 
                      id="outlined-code"
                      label="User"
                      value={user}
                      size="small"
                      onChange={handleChangeUser}
                    />
                  </Item>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Item sx={{ mt: 2 }}>
                  <Autocomplete
                  disabled={!state} 
                    sx={{ maxWidth: '100%' }}
                    id="combo-box-demo"
                    value={logo}
                    onChange={(event, newValue) => {
                      if (newValue!==null) 
                        setLogo(newValue);
                    }}
                    options={logos}
                    size="small"
                    renderInput={(params) => <TextField {...params} required label="Logo" />}
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <TextField
                    required
                    disabled={!state} 
                    id="outlined-name"
                    label="Name"
                    value={name}
                    size="small"
                    onChange={handleChangeName}
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  <Autocomplete
                    // disablePortal
                    disabled={!state} 
                    id="combo-box-demo_a"
                    value={rol}
                    onChange={(event, newValue) => {
                      if (newValue!==null) 
                        setRol(newValue);
                    }}
                    options={roles1}
                    size="small"
                    sx={{ maxWidth: '100%' }}
                    renderInput={(params) => <TextField {...params} required label="Roles" />}
                  />
                </Item>
              </Grid>
              <Grid item xs={6}>
                <Stack direction={'column'} alignContent="center" alignItems={'center'}>
                  <Item>
                    <TextField
                      required
                      disabled={!state} 
                      id="outlined-latitud"
                      label="Password"
                      // value={latitud}
                      size="small"
                      onChange={handleChangePassword}
                      type="password"
                    />
                  </Item>
                  <Item>
                    <TextField
                      required
                      disabled={!state} 
                      id="outlined-latitud_a"
                      label="Email"
                      placeholder="user@tek-cloud.cl"
                      // value={latitud}
                      size="small"
                      onChange={handleChangeEmail}
                      type="email"
                    />
                  </Item>

                  <Item>
                    <TextField
                      required
                      disabled={!state} 
                      id="outlined-longitud"
                      label="Phone"
                      size="small"
                      placeholder="9-9999-9999"
                      value={phone}
                      onChange={handleChangePhone}
                      type="tel"
                    />
                  </Item>
                  <Item>
                    <FormControlLabel
                    disabled={!state} 
                      control={<Switch color="primary" checked={status} onChange={handleChangeStatus} />}
                      label={status ? 'Operativo' : 'No Operativo'}
                    />
                  </Item>
                  <Item>
                    <FormControlLabel disabled={!state} control={<Checkbox onChange={handleChangeNotificationE} />} label="Email" />
                    <FormControlLabel disabled={!state}  control={<Checkbox onChange={handleChangeNotificationP} />} label="SMS" />
                  </Item>
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Item>
                  {state &&
                    state.ListOrg.length!==0 ?
                        <Autocomplete
                        multiple
                        disabled= {rol.label === 'admin' || rol.label === 'Sadmin'}
                        id="tags-standard"
                        sx={{ maxWidth: '100%' }}
                        options={state.ListOrg}
                        getOptionLabel={(option) => option.label}
                        defaultValue={[state.ListOrg[0]]}
                        onChange={(event, newValue) => {
                          setPrueba(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} variant="standard" label="Organizaciones" />}
                      />:'No Organization'
                  }

                </Item>
              </Grid>
            </Grid>
                    
            ):(
              <Stack sx={{ width: '100%' }} spacing={2} p={1} mt={2} mb={2}>
                <Alert severity="warning">Acceder desde Boton Agregar</Alert>
              </Stack>
            )
          }
            <Divider />
            <Divider />
            <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'}>
              <Item>
                <LoadingButton size="medium" variant="contained" onClick={handleNavigate} color="error">
                  <Iconify icon={'akar-icons:arrow-back-thick'} sx={{ mr: 0.5 }} />
                  Back
                </LoadingButton>
              </Item>
              <Item>
                {
                  state &&
                  state.ListOrg.length!==0 &&
                  <LoadingButton size="medium" variant="contained" type="submit" loading={loading} color="secondary">
                  <Iconify icon={'fluent:save-edit-20-regular'} sx={{ mr: 0.5 }} />
                  Save
                </LoadingButton>
                }
                
              </Item>
            </Stack>
          </form>
        </Card>
      </Scrollbar>
    </Container>
  );
};
export default CreateUserPage;
