import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import {
  styled,
  Paper,
  Typography,
  TextField,
  Divider,
  Autocomplete,
  Grid,
  Alert,
  FormControlLabel,
  Switch,
  Stack,
  Card,
  Container,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import Iconify from '../../components/iconify';
import { putTrafficligth } from '../../redux/trafficligthDucks';

const style = {
  width: 500,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'rigth',
  color: theme.palette.text.secondary,
}));

const EditTrafficligthPage = ({usuario,token}) => {
  const dispatch = useDispatch();

  const { state } = useLocation();

  const load = useSelector((store) => store.trafficligth.loadingTraff);
  const err = useSelector((store) => store.trafficligth.errorTraff);
  const traff = useSelector((store) => store.trafficligth.traff);
 
  const [loading, setLoading] = useState(true);
  const [error,setError] = useState('')

  // Codigo para emular la carga desde BD
  useEffect(() => {
    if ( err==='') {
      setLoading(false);
      setError(err)
    }else{
      setError(err)
      setLoading(false)
    }
  }, [err]);

  useEffect(() => {
    if (traff) navigate(-1);
  }, [load]);


  const [code, setCode] = useState(state?state.Organization.id:'');

  const [name, setName] = useState(state?state.Organization.name:'');
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const [tokenLocal, setTokenLocal] = useState(state?state.Organization.token:'');
  const handleChangeToken = (event) => {
    setTokenLocal(event.target.value);
  };

  const [latitud, setLatitud] = useState(state?state.Organization.latitud:'');
  const handleChangeLat = (event) => {
    setLatitud(event.target.value);
  };

  const [longitud, setLongitud] = useState(state?state.Organization.longitud:'');
  const handleChangeLong = (event) => {
    setLongitud(event.target.value);
  };

  const [status, setStatus] = useState(state?state.Organization.status:false);
  const handleChangeStatus = (event) => {
    setStatus(event.target.checked);
  };

  const datos = state?state.ListOrg:[];
  // console.log(datos)
  const result = datos.filter((item) => item.code === state.Organization.organizationId);
  const [organization, setOrganization] = useState(result[0]);
  const statustraffic = state?state.Organization.statustrafficligth:'';
  const updatedBy= state?state.Organization.updatedBy:'';
  const createdBy = state?state.Organization.createdBy:'';
  const handleSaveOrganization = async (e) => {
    e.preventDefault();
    const organizationItem = {
      code,
      name,
      latitud,
      longitud,
      organization: organization.code,
      token:tokenLocal,
      statustraffic,
      status,
      createdBy,
      updatedBy,
    };

    dispatch(putTrafficligth(token , organizationItem, usuario));
  };

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  return (
    <Container sx={style}>
      <Stack spacing={2} justifyContent="center">
        <Typography variant="h5" gutterBottom>
          Edit Trafficligth
        </Typography>
      </Stack>
      <Card>
        <form onSubmit={handleSaveOrganization}>
          {error !== '' && (
            <Stack sx={{ width: '100%' }} spacing={2} p={1}>
              <Alert severity="error">{error}</Alert>
            </Stack>
          )}
        {!state ? (
          <Stack sx={{ width: '100%' }} spacing={2} p={1} mt={2}>
            <Alert severity="warning">Acceder desde Boton Editar</Alert>
          </Stack>
        ):
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} p={1} mt={2}>
            <Grid item xs={6}>
              <Item>
                <TextField disabled id="outlined-code" label="Code" value={code} size="small" />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  disabled={!state}
                  id="outlined-name"
                  label="Name"
                  value={name}
                  size="small"
                  onChange={handleChangeName}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  disabled={!state}
                  id="outlined-latitud"
                  label="Latitud"
                  value={latitud}
                  size="small"
                  onChange={handleChangeLat}
                  type="number"
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  disabled={!state}
                  id="outlined-longitud"
                  label="Longitud"
                  size="small"
                  value={longitud}
                  onChange={handleChangeLong}
                  type="number"
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="center" direction={'column'}>
                <Item>
                  <TextField
                    required
                    disabled={!state}
                    id="outlined-url"
                    label="Token"
                    size="small"
                    value={tokenLocal}
                    type="text"
                    onChange={handleChangeToken}
                  />
                </Item>
                <Item>
                  <FormControlLabel
                  disabled={!state}
                    control={<Switch color="primary" checked={status} onChange={handleChangeStatus} />}
                    label={status ? 'Operativo' : 'No Operativo'}
                  />
                </Item>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <Autocomplete
                  id="combo-box-demo"
                  disabled={!state}
                  value={organization}
                  onChange={(event, newValue) => {
                    setOrganization(newValue);
                  }}
                  options={datos}
                  size="small"
                  sx={{ maxWidth: '100%' }}
                  renderInput={(params) => <TextField {...params} required label="Organization" />}
                />
              </Item>
            </Grid>
          </Grid>
          }
          <Divider sx={{ mt: 2, mb: 2 }} />

          <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'}>
            <Item>
              <LoadingButton size="medium" variant="contained" onClick={handleNavigate} color="error">
                <Iconify icon={'akar-icons:arrow-back-thick'} sx={{ mr: 0.5 }} />
                Back
              </LoadingButton>
            </Item>
            <Item>
              {
                state &&
                <LoadingButton size="medium" variant="contained" type="submit" loading={loading} color="secondary">
                <Iconify icon={'fluent:save-edit-20-regular'} sx={{ mr: 0.5 }} />
                Save
              </LoadingButton>
              }

            </Item>
          </Stack>
        </form>
      </Card>
    </Container>
  );
};

export default EditTrafficligthPage;
