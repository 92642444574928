import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, Alert, Snackbar } from '@mui/material';
import Moment from 'react-moment';

import { MapContainer, TileLayer, Marker, LayersControl } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

import L from 'leaflet';

const colores = [
  '/assets/images/markers/marker-icon-green.png',
  '/assets/images/markers/marker-icon-black.png',
  '/assets/images/markers/marker-icon-blue.png',
  '/assets/images/markers/marker-icon-red.png',
  '/assets/images/markers/marker-icon-gold.png',
];
const shadow = '/assets/images/markers/marker-shadow.png';

const greenIcon = new L.Icon({
  iconUrl: colores[0],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const blueIcon = new L.Icon({
  iconUrl: colores[2],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const auxIcon = new L.Icon({
  iconUrl: colores[2],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const blackIcon = new L.Icon({
  iconUrl: colores[1],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const redIcon = new L.Icon({
  iconUrl: colores[3],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const yellowIcon = new L.Icon({
  iconUrl: colores[4],
  shadowUrl: shadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const MarkerUGAT = ({
  user,
  TotalTrafficControl,
  TotalTrafficEmpalme,
  TotalTrafficLuces,
  TotalTrafficOffline,
  TotalTrafficOnline,
  TotalTrafficAuxiliar,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [msgcolor, setMsgcolor] = useState('green');
  const handleMarkerClick = (mark, estado, color) => {
    // setReloj(0);
    setMsgcolor(color);
    const msg = (
      <p>
        Codigo: {mark.id} <br />
        Nombre {mark.name} <br />
        Estado: {estado}
        <br />
        Actualizado el <Moment format="DD/MM/YY HH:mm">{mark.updatedAt}</Moment> <br />
        {user.roles !== 'user' && (
          <>
            Operador: {mark.operator}
            <br />
            Signal: {mark.signal} dbm
          </>
        )}
      </p>
    );
    setMessage(msg);
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const markerAuxliar = TotalTrafficAuxiliar?.map((mark, index) => {
    return (
      <Marker
        key={index}
        position={[mark.latitud, mark.longitud]}
        icon={auxIcon}
        eventHandlers={{ click: () => handleMarkerClick(mark, 'Auxiliar',  'blue') }}
      />
    );
  });

  const markerOperativo = TotalTrafficOnline?.map((mark, index) => {
    return (
      <Marker
        key={index}
        position={[mark.latitud, mark.longitud]}
        icon={greenIcon}
        eventHandlers={{ click: () => handleMarkerClick(mark, 'Operativo', '#009F3C') }}
      />
    );
  });

  const markerOffline = TotalTrafficOffline?.map((mark, index) => {
    return (
      <Marker
        key={index}
        position={[mark.latitud, mark.longitud]}
        icon={blueIcon}
        eventHandlers={{ click: () => handleMarkerClick(mark, 'Offline', '#1F8BE1') }}
      />
    );
  });

  const markerEmpalme = TotalTrafficEmpalme?.map((mark, index) => {
    return (
      <Marker
        key={index}
        position={[mark.latitud, mark.longitud]}
        icon={blackIcon}
        eventHandlers={{ click: () => handleMarkerClick(mark, 'Empalme', 'black') }}
      />
    );
  });

  const markerControl = TotalTrafficControl?.map((mark, index) => {
    return (
      <Marker
        key={index}
        position={[mark.latitud, mark.longitud]}
        icon={redIcon}
        eventHandlers={{ click: () => handleMarkerClick(mark, 'Control', '#BB2000') }}
      />
    );
  });

  const markerLuces = TotalTrafficLuces?.map((mark, index) => {
    return (
      <Marker
        key={index}
        position={[mark.latitud, mark.longitud]}
        icon={yellowIcon}
        eventHandlers={{ click: () => handleMarkerClick(mark, 'Luces', '#F1C40F ') }}
      />
    );
  });
  return (
    <Card sx={{ height: '30rem' }}>
      <MapContainer center={[user.latitud, user.longitud]} zoom={user.roles === 'user' ? 13 : 9}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> | <a href="http://tekchile.cl">Tek Chile SA</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <LayersControl position="topright">
          <LayersControl.Overlay checked name="ONLINE">
            <MarkerClusterGroup chunkedLoading>{markerOperativo}</MarkerClusterGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="AUXILIAR">
            <MarkerClusterGroup chunkedLoading>{markerAuxliar}</MarkerClusterGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="OFFLINE">
            <MarkerClusterGroup chunkedLoading>{markerOffline}</MarkerClusterGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="EMPALME">
            <MarkerClusterGroup chunkedLoading>{markerEmpalme}</MarkerClusterGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="CONTROL">
            <MarkerClusterGroup chunkedLoading>{markerControl}</MarkerClusterGroup>
          </LayersControl.Overlay>

          <LayersControl.Overlay checked name="LUCES">
            <MarkerClusterGroup chunkedLoading>{markerLuces}</MarkerClusterGroup>
          </LayersControl.Overlay>
        </LayersControl>
      </MapContainer>
      <Snackbar
        open={open}
        onClose={handleClose}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert icon={false} onClose={handleClose} sx={{ width: '100%', color: 'white', backgroundColor: msgcolor }}>
          {message}
        </Alert>
      </Snackbar>
    </Card>
  );
};

MarkerUGAT.propTypes = {};

export default MarkerUGAT;
