// Se encarga de listar los items en el navbar
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
  // user:PropTypes.object,
};

export default function NavSection({ data = [], user , ...other }) {
  // TODO agregar loading para evitar que se vean las opciones
  return (user.roles==="user"|| user.roles==='operator')?(
    (
      <Box {...other}>
        <List disablePadding sx={{ p: 1 }}>
          {
              data.map((item,index) => (
                  index<3 && <NavItem key={item.title} item={item} />
              ))
          }
        </List>
      </Box>
    )
  ) :( user.roles==='admin'?(
    <Box {...other}>
    <List disablePadding sx={{ p: 1 }}>
      {
          data.map((item,index) => (
           index < 5 && <NavItem key={item.title} item={item} />
          ))
      }
    </List>
  </Box>
  ):
    (
      <Box {...other}>
        <List disablePadding sx={{ p: 1 }}>
          {
              data.map((item) => (
                <NavItem key={item.title} item={item} />
              ))
          }
        </List>
      </Box>
    )
  )
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>

      <ListItemText disableTypography primary={title} />

      {info && info}
    </StyledNavItem>
  );
}
