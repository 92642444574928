import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  MenuItem,
  styled,
  Paper,
  Container,
  Stack,
  Card,
  Divider,
  Grid,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  FormControlLabel,
  Alert,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { postTrafficligth } from '../../redux/trafficligthDucks';

import Iconify from '../../components/iconify';

const style = {
  width: 500,
};
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));

const CreateTrafficligthPage = ({usuario,token}) => {
  const dispatch = useDispatch();

  const { state } = useLocation();

  const load = useSelector((store) => store.trafficligth.loadingTraff);
  const err = useSelector((store) => store.trafficligth.errorTraff);
  const traff = useSelector((store) => store.trafficligth.traff);
 
  const [loading, setLoading] = useState(true);
  const [error,setError] = useState('')

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };

  useEffect(() => {
    if ( err==='') {
      setLoading(false);
      setError(err)
    }else{
      setError(err)
      setLoading(false)
    }
  }, [err]);

  useEffect(() => {
    if (traff) navigate(-1);
  }, [load]);


  const [code, setCode] = useState('');
  const handleChangeCode = (event) => {
    setCode(event.target.value);
  };

  const [name, setName] = useState('');
  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const [tokenLocal, setTokenLocal] = useState('');
  const handleChangeToken = (event) => {
    setTokenLocal(event.target.value);
  };

  const [latitud, setLatitud] = useState(-38.737458774894144);
  const handleChangeLat = (event) => {
    setLatitud(event.target.value);
  };

  const [longitud, setLongitud] = useState(-72.59668028696235);
  const handleChangeLong = (event) => {
    setLongitud(event.target.value);
  };

  const [status, setStatus] = useState(true);
  const handleChangeStatus = (event) => {
    setStatus(event.target.checked);
  };

  const [organization, setOrganization] = useState(state?state.ListOrg[0]:[]);
  const datos = state?state.ListOrg:[];

  const handleSaveTrafficligth = async (e) => {
    e.preventDefault();
    setLoading(true);
    const organizationItem = {
      code,
      name,
      token:tokenLocal,
      status,
      organization: organization.code,
      latitud,
      longitud,
    };
    dispatch(postTrafficligth(token,organizationItem,usuario));
  };

  return (
    <Container sx={style}>
      <Stack direction={'row'} spacing={2} alignItems="center">
        <Typography variant="h5" gutterBottom>
          Create Trafficligth
        </Typography>
      </Stack>
      <Card>
        <form onSubmit={handleSaveTrafficligth}>
          {error !== '' && (
            <Stack sx={{ width: '100%' }} spacing={2} p={1}>
              <Alert severity="error">{error}</Alert>
            </Stack>
          )}
          {
            state ? 
            state.ListOrg.length !== 0 ?
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} p={1} mt={2}>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  id="outlined-code"
                  label="Code"
                  value={code}
                  size="small"
                  onChange={handleChangeCode}
                />
              </Item>{' '}
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  id="outlined-name"
                  label="Name"
                  value={name}
                  size="small"
                  onChange={handleChangeName}
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  id="outlined-latitud"
                  label="Latitud"
                  // value={latitud}
                  size="small"
                  onChange={handleChangeLat}
                  type="text"
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Item>
                <TextField
                  required
                  id="outlined-longitud"
                  label="Longitud"
                  size="small"
                  // value={longitud}
                  onChange={handleChangeLong}
                  type="text"
                />
              </Item>
            </Grid>
            <Grid item xs={6}>
              <Stack justifyContent="center" alignItems="center" direction={'column'} spacing={1}>
                <Item>
                  <TextField
                    required
                    id="outlined-url"
                    label="Token"
                    size="small"
                    // value={url}
                    type="password"
                    onChange={handleChangeToken}
                  />
                </Item>
                <Item>
                  <FormControlLabel
                    control={<Switch color="primary" checked={status} onChange={handleChangeStatus} />}
                    label={status ? 'Operativo' : 'No Operativo'}
                  />
                </Item>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Item>
                <Autocomplete
                  id="combo-box-demo"
                  value={organization}
                  onChange={(event, newValue) => {
                    console.log(newValue)
                    setOrganization(newValue);
                  }}
                  options={datos}
                  size="small"
                  sx={{ maxWidth: '100%' }}
                  renderInput={(params) => <TextField {...params} required label="Organization" />}
                />
              </Item>
            </Grid>
          </Grid>
          :
          <Stack sx={{ width: '100%' }} spacing={2} p={1} mt={2}>
              <Alert severity="warning">No Existen Organizaciones Disponibles</Alert>
            </Stack>
          :<Stack sx={{ width: '100%' }} spacing={2} p={1} mt={2}>
              <Alert severity="warning">Acceder desde Boton Agregar</Alert>
            </Stack>
          }
        

          <Divider sx={{ mt: 2, mb: 2 }} />

          <Stack justifyContent="center" alignItems="center" spacing={2} direction={'row'}>
            <Item>
              <LoadingButton size="medium" variant="contained" onClick={handleNavigate} color="error">
                <Iconify icon={'akar-icons:arrow-back-thick'} sx={{ mr: 0.5 }} />
                Back
              </LoadingButton>
            </Item>
            <Item>
              {
                state &&
                state.ListOrg.length!==0 &&
                <LoadingButton size="medium" variant="contained" type="submit" loading={loading} color="secondary">
                <Iconify icon={'fluent:save-edit-20-regular'} sx={{ mr: 0.5 }} />
                Save
              </LoadingButton>
              }
  
            </Item>
          </Stack>
        </form>
      </Card>
    </Container>
  );
};

export default CreateTrafficligthPage;
