import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Paper, Grid, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  width: '100%',
  maxWidth: 580,
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: 'white',
}));

const ToolbarMaps = ({
  user,
  TRAFFICLIGTHLIST,
  TotalTrafficAuxiliar,
  TotalTrafficControl,
  TotalTrafficEmpalme,
  TotalTrafficLuces,
  TotalTrafficOffline,
  TotalTrafficOnline,
}) => {
  const hora = new Date();
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} mb={2}>
      <Grid item xs={12} sm={2} md={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction={'column'} alignItems="center" justifyContent="space-between">
            <Item>
              <img alt="logo" src={user.logoUrl} style={{ width: user.roles === 'user' ? 90 : 130 }} />
            </Item>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={12} sm={1} md={1} />

      <Grid item xs={4} sm={3} md={2}>
        <Stack direction={'column'} alignItems="center" justifyContent="center" spacing={1}>
          <Item sx={{ background: '#228B22' }}>Online {TotalTrafficOnline?.length}</Item>
          <Item sx={{ background: 'blue' }}>Auxiliar {TotalTrafficAuxiliar?.length}</Item>
        </Stack>
      </Grid>
      <Grid item xs={4} sm={3} md={2}>
        <Stack direction={'column'} alignItems="end" justifyContent="flex-end" spacing={1}>
          <Item sx={{ background: '#6495ED' }}>Offline {TotalTrafficOffline?.length}</Item>
          <Item sx={{ background: 'black' }}>Empalme {TotalTrafficEmpalme?.length}</Item>
        </Stack>
      </Grid>
      <Grid item xs={4} sm={3} md={2}>
        <Stack direction={'column'} alignItems="end" justifyContent="flex-end" spacing={1}>
          <Item sx={{ background: 'red' }}>Control {TotalTrafficControl?.length}</Item>
          <Item sx={{ background: 'orange' }}>Luces {TotalTrafficLuces?.length}</Item>
        </Stack>
      </Grid>
      <Grid item xs={12} sm={1} md={1} />

      <Grid item xs={12} sm={12} md={2}>
        <Stack direction={'column'} alignItems="center">
          <Item sx={{ background: '#008B8B' }}>
            <Box display={'flex'} justifyContent={'center'}>
              Last Sync:
              <br />
              {/* <Divider orientation="vertical" flexItem sx={{mx:2}}/> */}
              {hora.toLocaleDateString()}
              {/* <Divider orientation="vertical" flexItem sx={{mx:2}}/> */}
              <br />
              {hora.toLocaleTimeString()}
            </Box>
          </Item>
        </Stack>
      </Grid>
    </Grid>
  );
};

ToolbarMaps.propTypes = {};

export default ToolbarMaps;
