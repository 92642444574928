import axios from 'axios';
// constantes
const dataMaps = {
  array: [],
  loadingmap: false,
};
const BaseUrl = process.env.REACT_APP_URL_API;
const LOADING = 'LOADING';
const ERROR = 'ERROR';
const GET_OBTENER_MAP = 'GET_OBETENER_MAP';

// reducer
export default function mapReducer(state = dataMaps, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, loadingmap: true };
    case ERROR: // TODO
      return { ...state, array: [], loading: false, successCreate: false, error: action.payload.error };
    case GET_OBTENER_MAP:
      return { ...state, array: action.payload.payload, loadingmap: false };
    default:
      return state;
  }
}

// acciones
// OBTENER INFORMACION
export const getMap = (token) => async (dispatch) => {
  try {
    const config = {
      method: 'get',
      url: `${BaseUrl}/api/trafficligth/maps`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    };
    const res = await axios(config);
    dispatch({
      type: GET_OBTENER_MAP,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};
