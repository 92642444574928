import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom"
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar,Button, Typography, OutlinedInput, InputAdornment, Tooltip,IconButton } from '@mui/material';
import { Add, AddCircleOutline } from '@mui/icons-material';
import { Stack } from '@mui/system';
// component
import Iconify from '../../../components/iconify';


// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 150,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 250,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

OrganizationListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  // select:PropTypes.array,
};

export default function OrganizationListToolbar({ numSelected, filterName, onFilterName,select, usuario}) {
  const navigate = useNavigate();
  const handleNavigateCreate = ()=>{
    navigate('../organizationcreate')
  }

//  const { code } = useParams();
const user = usuario;

  const handleNavigateEdit = (code)=>{

    navigate(`./edit/${code}`)

}
const handleNavigateDelete = (code)=>{

  navigate(`./delete/${code}`)

}
  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search..."
          size='small'
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

      {numSelected > 0 ? (<>
      {numSelected>1 ?(
        <Stack direction={"row"} justifyContent="center" alignItems="center" spacing={1}> 
        {/* <DeleteModal  organization={select} id={"organization"}/> */}
        </Stack>
      ):(
        <Stack direction={"row"} justifyContent="center" alignItems="center" spacing={1}>
              <Button variant="contained" size='small' color="warning" onClick={()=>handleNavigateEdit(select.code)}>
              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                  Edit
              </Button>
              <Button onClick={()=>handleNavigateDelete(select.code)} variant="contained" size='small' color='error' >
                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 1 }} />
                    Delete
                </Button >
        </Stack>
      )}
      </>
      ) : (
        user.roles==="Operator"?'':
        <Tooltip title="Agregar">
        <IconButton  onClick={handleNavigateCreate} color='success'>
          <AddCircleOutline />
        </IconButton>
      </Tooltip>
      )}
    </StyledRoot>
  );
}
