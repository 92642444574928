import PropTypes from 'prop-types';
import {useNavigate} from "react-router-dom"
import {useSelector } from 'react-redux';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Toolbar, Typography, OutlinedInput, InputAdornment, Tooltip, IconButton } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: 150,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: 250,
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));

// ----------------------------------------------------------------------

UserListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  usuario:PropTypes.object
};

export default function UserListToolbar({ numSelected, filterName, onFilterName,usuario }) {

  const navigate = useNavigate();
  const list = useSelector(store=>store.user.org)
  const logo = useSelector(store=>store.user.logo)
  const roles = useSelector(store=>store.user.roles)
  let ListOrg=[]
  const user = usuario;

  const handleNavigateCreate = ()=>{

  ListOrg =  list.map((org)=>{
  return  {label:org.id}
  })

    navigate('../usercreate',{state:{ListOrg,logo,roles}})
  }

  return (
    <StyledRoot
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <StyledSearch
          value={filterName}
          onChange={onFilterName}
          placeholder="Search ..."
          size='small'
          startAdornment={
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          }
        />
      )}

{user.roles==="Operator" ?'':(
               <Tooltip title="Agregar">
               <IconButton  onClick={handleNavigateCreate} color='success' >
                 <AddCircleOutline />
               </IconButton>
             </Tooltip>
          )      
      }
    </StyledRoot>
  );
}
