import axios from 'axios';
// constantes
const dataTrafficligth = {
  array: [],
  org: [],
  loadingTraff: false,
  errorTraff: '',
  traff:null
};
const BaseUrl = process.env.REACT_APP_URL_API;

const LOADING = 'LOADING_TRAFFICLIGTH';
const ERROR = 'ERROR_TRAFFICLIGTH';
const GET_OBTENER_TRAFFICLIGTH = 'GET_OBETENER_TRAFFICLIGTH';
const POST_CREAR_TRAFFICLIGTH = 'POST_CREAR_TRAFFICLIGTH';
const PUT_EDITAR_TRAFFICLIGTH = 'PUT_EDITAR_TRAFFICLIGTH';
const DELETE_ELIMINAR_TRAFFICLIGTH = 'DELETE_ELIMINAR_TRAFFICLIGTH';

// reducer
export default function trafficligthReducer(state = dataTrafficligth, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, 
        loadingTraff: true,
        errorTraff:"",
        traff:null };
    case ERROR: // TODO
      return { ...state, 
        loadingTraff: false,
        errorTraff: action.payload.error,
        traff:null };
    case GET_OBTENER_TRAFFICLIGTH:
      return {
        ...state,
        array: action.payload.payload[0],
        org: action.payload.payload[1],
        loadingTraff: false,
        errorTraff: "",
        traff:null
      };
    case POST_CREAR_TRAFFICLIGTH:
      return {
        ...state,
        loadingTraff: false,
        errorTraff: "",
        traff:true
      };
    case PUT_EDITAR_TRAFFICLIGTH:
      return {
        ...state,
        loadingTraff: false,
        errorTraff: "",
        traff:true
      };
    case DELETE_ELIMINAR_TRAFFICLIGTH:
      return {
        ...state,
        loadingTraff: false,
        errorTraff: "",
        traff:true
      };
    default:
      return state;
  }
}

// acciones
// OBTENER INFORMACION
export const getTrafficligth = (token) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const config = {
      method: 'get',
      url: `${BaseUrl}/api/trafficligth`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    };
    const res = await axios(config);
    dispatch({
      type: GET_OBTENER_TRAFFICLIGTH,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

// CREAR ORGANIZACION
export const postTrafficligth = (token1,trafficligth,usuario) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
    const { code, name, status, latitud, longitud, token, organization } = trafficligth;
    const data = {
      id:code,
      name,
      status,
      organizationId:organization,
      statustrafficligth: 10111,
      latitud,
      longitud,
      token,
      createdBy: usuario.code,
      updatedBy: usuario.code,
    };

    const config = {
      method: 'post',
      url: `${BaseUrl}/api/trafficligth`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token1,
      },
      data:{data},
    };
    const res = await axios(config);

    dispatch({
      type: POST_CREAR_TRAFFICLIGTH,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

// EDITAR ORGANIZATION
export const putTrafficligth = (token1,trafficligth,usuario) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const { code, name, organization, status, latitud, statustraffic, longitud, token, createdBy} =
      trafficligth;
    // Se debe crear con data para que el backend lo reconzoa automaticamente

    const data = {
      id:code,
      name,
      organizationId:organization,
      status,
      latitud,
      statustrafficligth:statustraffic,
      longitud,
      token,
      createdBy,
      updatedBy:usuario.code,
    };

    const config = {
      method: 'put',
      url: `${BaseUrl}/api/trafficligth`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token1,
      },
      data:{id:code,data},
    };

    const response = await axios(config);

    dispatch({
      type: PUT_EDITAR_TRAFFICLIGTH,
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};
// TODO: Faltan todas las validaciones
export const deleteTrafficligth = (token,code) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const config = {
      method: 'delete',
      url: `${BaseUrl}/api/trafficligth`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data: { id:code },
    };

    const resDelete = await axios(config);

    dispatch({
      type: DELETE_ELIMINAR_TRAFFICLIGTH,
      payload: resDelete.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};
