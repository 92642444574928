import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import {
  Table,
  Stack,
  Tooltip,
  IconButton,
  Paper,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { AssignmentOutlined, Download, TrafficOutlined } from '@mui/icons-material';

import Moment from 'react-moment';

import { postExport } from '../../../redux/logDucks';

import Label from '../../../components/label';

import { LogListHead, LogListToolbar } from './index';
import Scrollbar from '../../../components/scrollbar';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function TableContent({ user, token, LOGLIST, headLabel, pageLocal }) {
  const dispatch = useDispatch();

  // console.log(LOGLIST)
  const [page, setPage] = useState(LOGLIST ? pageLocal : 0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState(user.roles === 'user' ? 'id' : 'organizationId');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const navigate = useNavigate();

  const handleDetails = (row) => {
    const { id, organizationId, name } = row;
    window.localStorage.setItem('pageState', JSON.stringify(page));
    navigate(`../details`, { state: { id, organizationId, name, page } });
  };

  const handleDownload = (trafficligth) => {
    const { id } = trafficligth;
    dispatch(postExport(token, id));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - LOGLIST.length) : 0;

  const filteredUsers = applySortFilter(LOGLIST, getComparator(order, orderBy), filterName);
  // console.log(filteredUsers)

  const isNotFound = !filteredUsers.length && !!filterName;
  return (
    <>
      <LogListToolbar filterName={filterName} select={'log'} onFilterName={handleFilterByName} page />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <LogListHead
              order={order}
              orderBy={orderBy}
              headLabel={headLabel}
              rowCount={LOGLIST.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                const { id, organizationId, name, statustrafficligth, updatedAt } = row;

                return (
                  <TableRow hover key={id} tabIndex={-1} role="checkbox" size="small">
                    {user.roles !== 'user' && (
                      <TableCell component="th" scope="row" size="small">
                        <Typography variant="subtitle2">{organizationId}</Typography>
                      </TableCell>
                    )}

                    <TableCell align="left" size="small">
                      <Typography variant="subtitle2">{id}</Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="subtitle2">{name}</Typography>
                    </TableCell>

                    <TableCell align="left" size="small">
                      <Label
                        sx={{ width: '30%', p: 2 }}
                        color={
                          statustrafficligth === '101111'
                            ? 'info'
                            : statustrafficligth === '110111' || statustrafficligth === '10111'
                            ? 'primary'
                            : statustrafficligth === '111011' || statustrafficligth === '11011'
                            ? 'default'
                            : statustrafficligth === '111101' || statustrafficligth === '11101'
                            ? 'error'
                            : statustrafficligth === '111110' || statustrafficligth === '11110'
                            ? 'warning'
                            : 'success'
                        }
                      >
                        <Tooltip
                          title={
                            statustrafficligth === '101111'
                              ? 'Auxiliar'
                              : statustrafficligth === '110111' || statustrafficligth === '10111'
                              ? 'Sin Info...'
                              : statustrafficligth === '111011' || statustrafficligth === '11011'
                              ? 'Empalme'
                              : statustrafficligth === '111101' || statustrafficligth === '11101'
                              ? 'Control'
                              : statustrafficligth === '111110' || statustrafficligth === '11110'
                              ? 'Luces'
                              : 'Operativo'
                          }
                        >
                          <TrafficOutlined />
                        </Tooltip>
                      </Label>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Moment format="DD/MM/YY HH:mm">{updatedAt}</Moment>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Stack justifyContent="left" alignItems="center" direction={'row'}>
                        <Tooltip title="Detalles">
                          <IconButton onClick={() => handleDetails(row)} color="primary">
                            <AssignmentOutlined />
                          </IconButton>
                        </Tooltip>
                        {user.roles === 'admin' || user.roles === 'Sadmin' ? (
                          <Tooltip title="Descargar" onClick={() => handleDownload(row)}>
                            <IconButton color="success">
                              <Download />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          ''
                        )}
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 23 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{filterName}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={LOGLIST.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

// export default TableContent
