import axios from 'axios';
// constantes
const dataOrganization = {
  array: [],
  loadingOrg: false,
  errorOrg: '',
  Org:null
};
const BaseUrl = process.env.REACT_APP_URL_API;
const LOADING = 'LOADING_ORG';
const ERROR = 'ERROR_ORG';
const GET_OBTENER_ORGANIZATION = 'GET_OBETENER_ORGANIZATION';
const CODE_OBTENER_ORGANIZATION = 'CODE_OBETENER_ORGANIZATION';
const POST_CREAR_ORGANIZATION = 'POST_CREAR_ORGANIZATION';
const PUT_EDITAR_ORGANIZATION = 'PUT_EDITAR_ORGANIZATION';
const DELETE_ELIMINAR_ORGANIZATION = 'DELETE_ELIMINAR_ORGANIZATION';

// reducer
export default function organizationReducer(state = dataOrganization, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, 
        loadingOrg: true, 
        errorOrg:'',
        Org:null };
    case ERROR: // TODO
      return { ...state, 
        array: [], 
        loadingOrg: false, 
        errorOrg: action.payload.error,
        Org:null };
    case GET_OBTENER_ORGANIZATION:
      return {
        ...state,
        array: action.payload.payload,
        loadingOrg: false,
        errorOrg: '',
        Org:null
      };
    case CODE_OBTENER_ORGANIZATION:
      return {
        ...state,
        array: action.payload.payload,
        loadingOrg: false,
        errorOrg: '',
        Org:null
      };
    case POST_CREAR_ORGANIZATION:
      return {
        ...state,
        loadingOrg: false,
        errorOrg: '',
        Org:true
      };
    case PUT_EDITAR_ORGANIZATION:
      return {
        ...state,
        loadingOrg: false,
        errorOrg: '',
        Org:true
      };
    case DELETE_ELIMINAR_ORGANIZATION:
      return {
        ...state,
        loadingOrg: false,
        errorOrg: '',
        Org:true
      };
    default:
      return state;
  }
}

// acciones
export const loading = () => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
  } catch (error) {
    console.log(error);
  }
};

// OBTENER INFORMACION
export const getOrganization = (token) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const config = {
      method: 'get',
      url: `${BaseUrl}/api/organization`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    };
    const res = await axios(config);

    dispatch({
      type: GET_OBTENER_ORGANIZATION,
      payload: res.data,
    });
  } catch (error) {
    // console.log(error.response.data)
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

// No se esta ocupando
export const getOrganizationCreate = () => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const token = localStorage.getItem('authToken');

    const config = {
      method: 'get',
      url: `${BaseUrl}/api/organization`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    };
    await axios(config);
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

// CREAR ORGANIZACION
export const postOrganization = (token,organization,usuario) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
    const { code, name, logo, status, latitud, longitud } = organization;
    const data = {
      id:code,
      name,
      logo,
      status,
      latitud,
      longitud,
      createdBy: usuario.code,
      updatedBy: usuario.code,
    };

    const config = {
      method: 'post',
      url: `${BaseUrl}/api/organization`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data:{data},
    };
    const res = await axios(config);

    dispatch({
      type: POST_CREAR_ORGANIZATION,
      payload: res.data,
    });

  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

// OBTENER POR ID (nO SE ESTA OCUPANDO)
export const getCodeOrganization = (code) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });
    const config = {
      method: 'post',
      url: `${BaseUrl}/api/organization/sql/find`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: { code },
    };

    const res = await axios(config);

    dispatch({
      type: CODE_OBTENER_ORGANIZATION,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

// EDITAR ORGANIZATION
export const putOrganization = (token,organization,usuario) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const { id, name, logo, status, latitud, longitud, createdBy } = organization;
    const data = {
      id,
      name,
      logo,
      status,
      latitud,
      longitud,
      createdBy,
      updatedBy:usuario.code,
    };

    const config = {
      method: 'put',
      url: `${BaseUrl}/api/organization`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data:{id,data},
    };

    const res = await axios(config);
    dispatch({
      type: PUT_EDITAR_ORGANIZATION,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};
// TODO: Faltan todas las validaciones
export const deleteOrganization = (token,code) => async (dispatch) => {
  try {
    dispatch({
      type: LOADING,
    });

    const config = {
      method: 'delete',
      url: `${BaseUrl}/api/organization`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
      data: { id:code },
    };
    const res = await axios(config);

    dispatch({
      type: DELETE_ELIMINAR_ORGANIZATION,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};
