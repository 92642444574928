import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// @mui
import {
  Card,
  Stack,
  Paper,
  Avatar,
  TextField,
  styled,
  Container,
  Typography,
  Grid,
  Alert,
  Autocomplete,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';

import { getPassUbic, putPassword, putUbicacion, putAvatar } from '../../redux/profileDucks';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
}));
const logos = [
  { label: 'Avatar1', url: '/assets/images/avatars/avatar_1.jpg' },
  { label: 'Avatar2', url: '/assets/images/avatars/avatar_2.jpg' },
  { label: 'Avatar3', url: '/assets/images/avatars/avatar_3.jpg' },
  { label: 'Avatar4', url: '/assets/images/avatars/avatar_4.jpg' },
  { label: 'Avatar5', url: '/assets/images/avatars/avatar_5.jpg' },
  { label: 'Avatar6', url: '/assets/images/avatars/avatar_6.jpg' },
  { label: 'Avatar7', url: '/assets/images/avatars/avatar_7.jpg' },
  { label: 'Avatar8', url: '/assets/images/avatars/avatar_8.jpg' },
  { label: 'Avatar9', url: '/assets/images/avatars/avatar_9.jpg' },
  { label: 'Avatar10', url: '/assets/images/avatars/avatar_10.jpg' },
  { label: 'Avatar11', url: '/assets/images/avatars/avatar_11.jpg' },
  { label: 'Avatar12', url: '/assets/images/avatars/avatar_12.jpg' },
  { label: 'Avatar13', url: '/assets/images/avatars/avatar_13.jpg' },
  { label: 'Avatar14', url: '/assets/images/avatars/avatar_14.jpg' },
  { label: 'Avatar15', url: '/assets/images/avatars/avatar_15.jpg' },
  { label: 'Avatar16', url: '/assets/images/avatars/avatar_16.jpg' },
  { label: 'Avatar17', url: '/assets/images/avatars/avatar_17.jpg' },
  { label: 'Avatar18', url: '/assets/images/avatars/avatar_18.jpg' },
  { label: 'Avatar19', url: '/assets/images/avatars/avatar_19.jpg' },
  { label: 'Avatar20', url: '/assets/images/avatars/avatar_20.jpg' },
];

const ProfilePage = ({usuario,token}) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const user = usuario;
  useEffect(() => {
    dispatch(getPassUbic());
  }, []);

  // Contraseña
  const mensaje = useSelector((store) => store.profile.message);
  const [message, setMessage] = useState(mensaje);
  const err = useSelector((store) => store.profile.error);
  const [error, setError] = useState(err);

  const [passold, setPassold] = useState();
  const [passnew, setPassnew] = useState();
  const [passnewconfirm, setPassnewconfirm] = useState();

  useEffect(() => {
    setError(err);
    setMessage(mensaje);
  }, [err,mensaje]);

  const handleChangePassold = (event) => {
    setPassold(event.target.value);
  };
  const handleChangePassnew = (event) => {
    setPassnew(event.target.value);
  };
  const handleChangePassnewconfirm = (event) => {
    setPassnewconfirm(event.target.value);
  };
  const handleChangePassword = async (e) => {
    e.preventDefault();
    const userPassword = {
      code: user.code,
      passold,
      passnew,
      passnewconfirm,
    };

    dispatch(putPassword(token,userPassword));
  };

  // Latitud Longitud

  const [latitud, setLatitud] = useState();
  const [longitud, setLongitud] = useState();

  const handleChangeLatitud = (event) => {
    setLatitud(event.target.value);
  };
  const handleChangeLongitud = (event) => {
    setLongitud(event.target.value);
  };

  const handleChangeUbicacion = async (e) => {
    e.preventDefault();
    const userUbicacion = {
      code: user.code,
      latitud,
      longitud,
    };
    // console.log(userUbicacion)
    dispatch(putUbicacion(token,userUbicacion));
  };

  // Avatar

  const logoActual = logos.filter((item) => item.url === user.logo);

  const [logo, setLogo] = useState(logoActual[0]);

  const handleChangeAvatar = async (e) => {
    e.preventDefault();
    const userAvatar = {
      code: user.code,
      avatar: logo,
    };
    //  console.log(userAvatar)
    dispatch(putAvatar(token,userAvatar));
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" gutterBottom>
          Profile Setting
        </Typography>
      </Stack>
      <Card>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} p={1}>
          <Grid item xs={12}>
            {message !== '' && (
              <Stack sx={{ width: '100%' }} spacing={2} p={1}>
                <Alert severity="success">{message}</Alert>
              </Stack>
            )}
            {error !== '' && (
              <Stack sx={{ width: '100%' }} spacing={2} p={1}>
                <Alert severity="error">{error}</Alert>
              </Stack>
            )}

            <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1}>
              <Typography variant="h6" gutterBottom>
                Cambio de Contraseñas
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={6} sm={4} md={3}>
            <Item>
              <TextField
                required
                id="outlined-password-old"
                label="Old"
                size="small"
                type={'password'}
                onChange={handleChangePassold}
              />
            </Item>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Item>
              <TextField
                required
                id="outlined-code_Old"
                label="New"
                size="small"
                type={'password'}
                onChange={handleChangePassnew}
              />
            </Item>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Item>
              <TextField
                required
                id="outlined-code_New"
                label="Confirm_New"
                size="small"
                type={'password'}
                onChange={handleChangePassnewconfirm}
              />
            </Item>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Item>
              <LoadingButton size="medium" variant="contained" color="primary" onClick={handleChangePassword}>
                Change...
              </LoadingButton>
            </Item>
          </Grid>

          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1}>
              <Typography variant="h6" gutterBottom>
                Avatar
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Item sx={{ mx: 5 }}>
              <Avatar alt="Avatar" src={logo.url} sx={{ height: 70, width: 70 }} />
            </Item>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <Item sx={{ mt: 2 }}>
              <Autocomplete
                id="autocomplete"
                value={logo}
                onChange={(event, newValue) => {
                  if (newValue != null) {
                    setLogo(newValue);
                  }
                }}
                options={logos}
                sx={{ maxWidth: '100%' }}
                size="small"
                renderInput={(params) => <TextField {...params} required label="Logo" />}
              />
            </Item>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Item sx={{ mt: 2.3}}>
              <LoadingButton size="medium" variant="contained" color="primary" onClick={handleChangeAvatar}>
                Change...
              </LoadingButton>
            </Item>
          </Grid>

          {user.roles !== 'user' && (
            <>
              <Grid item xs={12}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" ml={1}>
                  <Typography variant="h6" gutterBottom>
                    Ubicación en Mapa
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Item>
                  <TextField
                    required
                    id="outlined-code_Lat"
                    label="Latitud"
                    size="small"
                    onChange={handleChangeLatitud}
                    // value={latitud}
                  />
                </Item>
              </Grid>
              <Grid item xs={6} sm={4} md={3}>
                <Item>
                  <TextField
                    required
                    id="outlined-code_Long"
                    label="Longitud"
                    size="small"
                    onChange={handleChangeLongitud}
                    // value={longitud}
                  />
                </Item>
              </Grid>
              <Grid item xs={12} sm={4} md={3} >
                <Item>
                  <LoadingButton size="medium" variant="contained" color="primary" onClick={handleChangeUbicacion}>
                    Change...
                  </LoadingButton>
                </Item>
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </Container>
  );
};

export default ProfilePage;
