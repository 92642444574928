import { Provider } from "react-redux";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';

import generateStore from "./redux/store";

// ----------------------------------------------------------------------


export default function App() {
  const store = generateStore();
  return (
    <Provider store={store}>
      <ThemeProvider>
        <ScrollToTop />
        <StyledChart />
            <Router />   
      </ThemeProvider>
    </Provider>

  );
}
