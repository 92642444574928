import axios from 'axios';
import { saveAs } from 'file-saver';

// constantes
const dataLog = {
  array: [],
  details: [],
  loadingLog: false,
  errorLog:''
};
const BaseUrl = process.env.REACT_APP_URL_API;
const LOADING = 'LOADING_LOG';
const ERROR = 'ERROR_LOG';
const GET_OBTENER_LOGS = 'GET_OBETENER_LOGS';
const GET_DETAILS_LOGS = 'GET_DETAILS_LOGS';
// const POST_EXPORTS_LOGS = 'POST_EXPORTS_LOGS';

// reducer
export default function logReducer(state = dataLog, action) {
  switch (action.type) {
    case LOADING:
      return { ...state, 
        array: [], 
        details:[],
        loadingLog: true,
        errorLog:'',
      };
    case ERROR: // TODO
      return { ...state, 
        array: [], 
        details:[],
        loadingLog: false,  
        errorLog: action.payload.error };
    case GET_OBTENER_LOGS:
      return {
        ...state,
        array: action.payload.payload[0],
        loadingLog: false,
        errorLog: '',
      };
    case GET_DETAILS_LOGS:
      return {
        ...state,
        details: action.payload.payload,
        loadingLog: false,
        errorLog: '',
      };
    default:
      return state;
  }
}

// acciones
// OBTENER INFORMACION
export const getLogs = (token) => async (dispatch) => {
  try {

    // const logData = localStorage.getItem('logData');
    // if (logData){
    //   console.log("desde local storage")
    //   dispatch({
    //     type: GET_OBTENER_LOGS,
    //     payload: JSON.parse(logData) ,
    //   });
    // }else{
      console.log("desde internet")
      dispatch({
        type: LOADING,
      });
      const config = {
      method: 'get',
      url: `${BaseUrl}/api/trafficligth/log`,
      headers: {
        'Content-Type': 'application/json',
        'auth-token': token,
      },
    };
    const res = await axios(config);
    // console.log(res.data);
    localStorage.setItem('logData',JSON.stringify(res.data));
    // setTimeout(()=>{
    dispatch({
      type: GET_OBTENER_LOGS,
      payload: res.data,
    });
  // }
    // },2000)
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};

// CREAR ORGANIZACION
export const getDetails = (token,codeTraffic) => async (dispatch) => {
  try {
      dispatch({
        type: LOADING,
      });
      const config = {
        method: 'post',
        url: `${BaseUrl}/api/trafficligth/log`,
        headers: {
          'Content-Type': 'application/json',
          'auth-token': token,
        },
        data:{id:codeTraffic},
      };
      const res = await axios(config);

      dispatch({
        type: GET_DETAILS_LOGS,
        payload: res.data,
      });
    
  } catch (error) {
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};


export const postExport = (token,id) => async (dispatch) => {
  try {
const myInit = { method: 'POST',
               headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'auth-token': token
                },
               body: JSON.stringify({data:{id}
                })};

    fetch(`${BaseUrl}/api/trafficligth/export`,myInit)
    .then(response =>response.blob())
    .then(blob => {
      const fileName = 'log.xlsx';
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }).catch((error)=>{
      console.log(error)
    });

  } catch (error) {
    console.log(error)
    dispatch({
      type: ERROR,
      payload: error.response.data,
    });
  }
};